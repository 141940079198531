import React from 'react'
import {Form, Select} from 'antd'

interface EntryRequirementsInputProps {
    onChange: (e: any) => void,
    disabled: boolean,
    value: string,
    placeholder?: string,
    entryRequirements: {"name": string}[]
}

export default ({value, placeholder, entryRequirements, disabled, onChange}: EntryRequirementsInputProps) =>
  <Form.Item label='Entry requirements'>
    <Select
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
    >
      {entryRequirements.map(type =>
        <Select.Option value={type.name} key={type.name}>{type.name}</Select.Option>
      )}
    </Select>
  </Form.Item>
