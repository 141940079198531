import {Translations} from "../../../types";
import React from "react";
import { Typography } from "antd";
import {BlockProps} from "antd/lib/typography/Base";

interface Props {
  translations?: Translations
  locales: string[]
  ellipsis?: BlockProps["ellipsis"]
}

export default function({translations, locales, ellipsis}: Props) {
  const paragraphs = locales
    .map(locale => translations?.[locale]?.su)
    .concat(['No data'])
    .find(tr => !!tr)
    ?.split('\n')
    .map(line => (
      <Typography.Paragraph ellipsis={ellipsis}>
        {line}
      </Typography.Paragraph>
    ))

  return (
    <>
      {paragraphs}
    </>
  )
}
