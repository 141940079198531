import gql from 'graphql-tag'

const fields = `
id
passportCountry {
  id
  iata
  translations(filter: {locales: ["en"]})
}
originCountry {
  id
  iata
  translations(filter: {locales: ["en"]})
}
destination {
  __typename
  ... on Country {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
  ... on City {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
  ... on Airport {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
}
status
inheritStatus
open
inheritOpen
openingDate
inheritOpeningDate
directAllowed
inheritDirectAllowed
directAllowedDate
inheritDirectAllowedDate
vaccinationRequired
inheritVaccinationRequired
vaccinationPolicyTranslations(filter: {locales: ["en", "ru"]})
inheritVaccinationPolicyTranslations
certificateAfterRequired
inheritCertificateAfterRequired
certificateAfterPolicyTranslations(filter: {locales: ["en", "ru"]})
inheritCertificateAfterPolicyTranslations
certificateBeforeRequired
inheritCertificateBeforeRequired
certificateBeforePolicyTranslations(filter: {locales: ["en", "ru"]})
inheritCertificateBeforePolicyTranslations
certificateRequired
inheritCertificateRequired
certificatePolicyTranslations(filter: {locales: ["en", "ru"]})
inheritCertificatePolicyTranslations
quarantineRequired
inheritQuarantineRequired
quarantinePolicyTranslations(filter: {locales: ["en", "ru"]})
inheritQuarantinePolicyTranslations
insuranceRequired
inheritInsuranceRequired
insurancePolicyTranslations(filter: {locales: ["en", "ru"]})
inheritInsurancePolicyTranslations
masksRequired
inheritMasksRequired
masksPolicyTranslations(filter: {locales: ["en", "ru"]})
inheritMasksPolicyTranslations
foodOpen
inheritFoodOpen
foodPolicyTranslations(filter: {locales: ["en", "ru"]})
inheritFoodPolicyTranslations
sources
internalRestrictionsTranslations(filter: {locales: ["en", "ru"]})
inheritInternalRestrictionsTranslations
entryRestrictionsTranslations(filter: {locales: ["en", "ru"]})
inheritEntryRestrictionsTranslations
inheritForbiddenDirectCountries
forbiddenDirectCountries {
  id
  iata
  translations(filter: {locales: ["en"]})
}
inheritAllowedLayoverAirports
allowedLayoverAirports {
  id
  iata
  translations(filter: {locales: ["en"]})
}
transitInterlineAvailable
inheritTransitInterlineAvailable
inheritTransitTranslations
transitTranslations(filter: {locales: ["en", "ru"]})
lastModified
entryRequirements
`;

export const ListTravelRestrictionsQ = gql`
query {
  travelRestrictions {
    ${fields}
  }
}
`

export const TravelRestrictionQ = gql`
query GetTravelRestriction($id: Int!) {
  travelRestriction(id: $id) {
    ${fields}
  }
}
`

export const InputsDataQ = gql`
  query {
    countries {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
    cities {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
    airports {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
  }
`

export const FieldsChangelogQ = gql`
  query FieldsChangelog($id: ID!) {
    latestChangelog(entity: TRAVEL_RESTRICTION, entityId: $id) {
      editor
      change
      date
      field
    }
  }
`

export const InputsEntryRequirementsQ = gql`
  query {
    __type(name: "EntryRequirementsType") {
      enumValues {
        name
      }
    }
  }
`
