import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin, Input, Select, Form } from 'antd'
import * as yup from 'yup'
import equal from 'fast-deep-equal'
import { UpdateTravelRestrictionM } from './mutations'
import { Translations } from '../../../types'
import { TravelRestrictionQ, InputsDataQ, FieldsChangelogQ, InputsEntryRequirementsQ } from './queries'
import EntryRequirementsInput from './inputs/entry_requirements'
import TranslationsInput from '../../shared/translations'
import CountryInput from '../../shared/inputs/country'
import AirportInput from '../../shared/inputs/airport'
import PlaceInput from '../../shared/inputs/place'
import DateInput from '../../shared/inputs/date'
import BoolInput from '../../shared/inputs/bool'
import NullableBoolInput from '../../shared/inputs/nullable_bool'
import StringsInput from '../../shared/inputs/strings'
import LatestChange, { Data as LatestChangeData } from "../../shared/latest_change"

import '../../shared/form.css'

import {
  processErrors,
  preprocessJSONInputsGently,
  prepareMutationInput,
  prepareComponents,
  preprocessInputTranslation,
  snakeToCamel,
  capitalize
} from '../../../utils'
import { getRules, getRule } from '../../../access_rules'
import { Moment } from 'moment'
import { CardTabListType } from "antd/lib/card";
import Changelog from "../../shared/changelog";
import moment from 'moment'
import PlaceType from "../../shared/types/place";

interface UpdateTravelRestrictionInput {
  passportCountryId?: number
  originCountryId?: number
  destinationType?: PlaceType
  destinationId?: number
  status?: string | null
  inheritStatus?: boolean
  open?: boolean | null
  inheritOpen?: boolean
  openingDate?: Date | null
  inheritOpeningDate?: boolean
  directAllowed?: boolean | null
  inheritDirectAllowed?: boolean
  directAllowedDate?: Date | null
  inheritDirectAllowedDate?: boolean
  vaccinationRequired?: boolean | null
  inheritVaccinationRequired?: boolean
  vaccinationPolicyTranslations?: Translations
  inheritVaccinationPolicyTranslations?: boolean
  certificateAfterRequired?: boolean | null
  inheritCertificateAfterRequired?: boolean
  certificateAfterPolicyTranslations?: Translations | null
  inheritCertificateAfterPolicyTranslations?: boolean
  certificateBeforeRequired?: boolean | null
  inheritCertificateBeforeRequired?: boolean
  certificateBeforePolicyTranslations?: Translations | null
  inheritCertificateBeforePolicyTranslations?: boolean
  certificateRequired?: boolean | null
  inheritCertificateRequired?: boolean
  certificatePolicyTranslations?: Translations | null
  inheritCertificatePolicyTranslations?: boolean
  quarantineRequired?: boolean | null
  inheritQuarantineRequired?: boolean
  quarantinePolicyTranslations?: Translations | null
  inheritQuarantinePolicyTranslations?: boolean
  insuranceRequired?: boolean | null
  inheritInsuranceRequired?: boolean
  insurancePolicyTranslations?: Translations | null
  inheritInsurancePolicyTranslations?: boolean
  masksRequired?: boolean | null
  inheritMasksRequired?: boolean
  masksPolicyTranslations?: Translations | null
  inheritMasksPolicyTranslations?: boolean
  foodOpen?: boolean | null
  inheritFoodOpen?: boolean
  foodPolicyTranslations?: Translations | null
  inheritFoodPolicyTranslations?: boolean
  sources?: string[]
  internalRestrictionsTranslations?: Translations | null
  inheritInternalRestrictionsTranslations?: boolean
  entryRestrictionsTranslations?: Translations | null
  inheritEntryRestrictionsTranslations?: boolean
  forbiddenDirectCountryIds?: number[]
  inheritForbiddenDirectCountries?: boolean
  allowedLayoverAirportIds?: number[]
  inheritAllowedLayoverAirports?: boolean
  transitInterlineAvailable?: boolean | null
  inheritTransitInterlineAvailable?: boolean
  transitTranslations?: Translations | null
  inheritTransitTranslations?: boolean,
  entryRequirements?: string
}

export const schema = yup.object().shape({
  passportCountryId: yup.number().min(1).required(),
  originCountryId: yup.number().min(1).required(),
  destinationType: yup.string().required(),
  destinationId: yup.number().min(1).required(),
  status: yup.string().nullable(),
  inheritStatus: yup.boolean().required(),
  open: yup.boolean().nullable(),
  inheritOpen: yup.boolean().required(),
  openingDate: yup.date().nullable(),
  inheritOpeningDate: yup.boolean().required(),
  directAllowed: yup.boolean().nullable(),
  inheritDirectAllowed: yup.boolean().required(),
  directAllowedDate: yup.date().nullable(),
  inheritDirectAllowedDate: yup.boolean().required(),
  vaccinationRequired: yup.boolean().nullable(),
  inheritVaccinationRequired: yup.boolean().required(),
  vaccinationPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritVaccinationPolicyTranslations: yup.boolean().required(),
  certificateAfterRequired: yup.boolean().nullable(),
  inheritCertificateAfterRequired: yup.boolean().required(),
  certificateAfterPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificateAfterPolicyTranslations: yup.boolean().required(),
  certificateBeforeRequired: yup.boolean().nullable(),
  inheritCertificateBeforeRequired: yup.boolean().required(),
  certificateBeforePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificateBeforePolicyTranslations: yup.boolean().required(),
  certificateRequired: yup.boolean().nullable(),
  inheritCertificateRequired: yup.boolean().required(),
  certificatePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificatePolicyTranslations: yup.boolean().required(),
  quarantineRequired: yup.boolean().nullable(),
  inheritQuarantineRequired: yup.boolean().required(),
  quarantinePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritQuarantinePolicyTranslations: yup.boolean().required(),
  insuranceRequired: yup.boolean().nullable(),
  inheritInsuranceRequired: yup.boolean().required(),
  insurancePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritInsurancePolicyTranslations: yup.boolean().required(),
  masksRequired: yup.boolean().nullable(),
  inheritMasksRequired: yup.boolean().required(),
  masksPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritMasksPolicyTranslations: yup.boolean().required(),
  foodOpen: yup.boolean().nullable(),
  inheritFoodOpen: yup.boolean().required(),
  foodPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritFoodPolicyTranslations: yup.boolean().required(),
  sources: yup.array().of(yup.string()),
  internalRestrictionsTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritInternalRestrictionsTranslations: yup.boolean().required(),
  entryRestrictionsTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritEntryRestrictionsTranslations: yup.boolean().required(),
  forbiddenDirectCountryIds: yup.array().of(yup.number().min(1)),
  inheritForbiddenDirectCountries: yup.boolean().required(),
  allowedLayoverAirportIds: yup.array().of(yup.number().min(1)),
  inheritAllowedLayoverAirports: yup.boolean().required(),
  transitInterlineAvailable: yup.boolean().nullable(),
  inheritTransitInterlineAvailable: yup.boolean().required(),
  transitTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritTransitTranslations: yup.boolean().required(),
  notify_changes: yup.boolean(),
  entryRequirements: yup.string().required()
})

export default (props) => {
  const id = parseInt(props.match.params.id)

  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateTravelRestrictionInput>({})


  const {
    data: travelRestrictionData,
    error: travelRestrictionLoadingError,
    loading: travelRestrictionLoading
  } = useQuery(TravelRestrictionQ, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  const {
    data: fieldsChangelogData,
    error: fieldsChangelogLoadingError,
    loading: fieldsChangelogLoading
  } = useQuery(FieldsChangelogQ, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  const [updateTravelRestriction, { data: mutationData }] = useMutation(UpdateTravelRestrictionM, setErrors)

  if (travelRestrictionLoadingError) return (<Alert type='error' message='Travel Restriction fetching error' />)
  if (travelRestrictionLoading) return (<Spin size='large' />)
  if (travelRestrictionData.travelRestriction === undefined) return (<></>)

  const { travelRestriction } = travelRestrictionData
  if (fieldsChangelogLoadingError) return (<Alert type='error' message='Fields Changelog fetching error' />)
  if (fieldsChangelogLoading) return (<Spin size='large' />)
  if (fieldsChangelogData.latestChangelog === undefined) return (<></>)

  const fieldsChangelog: LatestChangeData[] = fieldsChangelogData.latestChangelog

  const latestFieldChanges = fieldsChangelog.reduce((acc, obj) => {
    acc[obj.field] = obj
    acc[obj.field].date = moment.utc(acc[obj.field].date).local().format('YYYY-MM-DD HH:mmZ')
    return acc
  }, {})

  const destinationType: PlaceType = travelRestriction.destination.__typename.toUpperCase()

  const defaultInput: UpdateTravelRestrictionInput = {
    passportCountryId: travelRestriction.passportCountry.id,
    originCountryId: travelRestriction.originCountry.id,
    destinationType: destinationType,
    destinationId: travelRestriction.destination.id,
    status: travelRestriction.status,
    inheritStatus: travelRestriction.inheritStatus,
    open: travelRestriction.open,
    inheritOpen: travelRestriction.inheritOpen,
    openingDate: travelRestriction.openingDate,
    inheritOpeningDate: travelRestriction.inheritOpeningDate,
    directAllowed: travelRestriction.directAllowed,
    inheritDirectAllowed: travelRestriction.inheritDirectAllowed,
    directAllowedDate: travelRestriction.directAllowedDate,
    inheritDirectAllowedDate: travelRestriction.inheritDirectAllowedDate,
    vaccinationRequired: travelRestriction.vaccinationRequired,
    inheritVaccinationRequired: travelRestriction.inheritVaccinationRequired,
    vaccinationPolicyTranslations: travelRestriction.vaccinationPolicyTranslations,
    inheritVaccinationPolicyTranslations: travelRestriction.inheritVaccinationPolicyTranslations,
    certificateAfterRequired: travelRestriction.certificateAfterRequired,
    inheritCertificateAfterRequired: travelRestriction.inheritCertificateAfterRequired,
    certificateAfterPolicyTranslations: travelRestriction.certificateAfterPolicyTranslations,
    inheritCertificateAfterPolicyTranslations: travelRestriction.inheritCertificateAfterPolicyTranslations,
    certificateBeforeRequired: travelRestriction.certificateBeforeRequired,
    inheritCertificateBeforeRequired: travelRestriction.inheritCertificateBeforeRequired,
    certificateBeforePolicyTranslations: travelRestriction.certificateBeforePolicyTranslations,
    inheritCertificateBeforePolicyTranslations: travelRestriction.inheritCertificateBeforePolicyTranslations,
    certificateRequired: travelRestriction.certificateRequired,
    inheritCertificateRequired: travelRestriction.inheritCertificateRequired,
    certificatePolicyTranslations: travelRestriction.certificatePolicyTranslations,
    inheritCertificatePolicyTranslations: travelRestriction.inheritCertificatePolicyTranslations,
    quarantineRequired: travelRestriction.quarantineRequired,
    inheritQuarantineRequired: travelRestriction.inheritQuarantineRequired,
    quarantinePolicyTranslations: travelRestriction.quarantinePolicyTranslations,
    inheritQuarantinePolicyTranslations: travelRestriction.inheritQuarantinePolicyTranslations,
    insuranceRequired: travelRestriction.insuranceRequired,
    inheritInsuranceRequired: travelRestriction.inheritInsuranceRequired,
    insurancePolicyTranslations: travelRestriction.insurancePolicyTranslations,
    inheritInsurancePolicyTranslations: travelRestriction.inheritInsurancePolicyTranslations,
    masksRequired: travelRestriction.masksRequired,
    inheritMasksRequired: travelRestriction.inheritMasksRequired,
    masksPolicyTranslations: travelRestriction.masksPolicyTranslations,
    inheritMasksPolicyTranslations: travelRestriction.inheritMasksPolicyTranslations,
    foodOpen: travelRestriction.foodOpen,
    inheritFoodOpen: travelRestriction.inheritFoodOpen,
    foodPolicyTranslations: travelRestriction.foodPolicyTranslations,
    inheritFoodPolicyTranslations: travelRestriction.inheritFoodPolicyTranslations,
    sources: travelRestriction.sources,
    internalRestrictionsTranslations: travelRestriction.internalRestrictionsTranslations,
    inheritInternalRestrictionsTranslations: travelRestriction.inheritInternalRestrictionsTranslations,
    entryRestrictionsTranslations: travelRestriction.entryRestrictionsTranslations,
    inheritEntryRestrictionsTranslations: travelRestriction.inheritEntryRestrictionsTranslations,
    forbiddenDirectCountryIds: travelRestriction.forbiddenDirectCountries.map(country => country.id),
    inheritForbiddenDirectCountries: travelRestriction.inheritForbiddenDirectCountries,
    allowedLayoverAirportIds: travelRestriction.allowedLayoverAirports.map(airport => airport.id),
    inheritAllowedLayoverAirports: travelRestriction.inheritAllowedLayoverAirports,
    transitInterlineAvailable: travelRestriction.transitInterlineAvailable,
    inheritTransitInterlineAvailable: travelRestriction.inheritTransitInterlineAvailable,
    transitTranslations: travelRestriction.transitTranslations,
    inheritTransitTranslations: travelRestriction.inheritTransitTranslations,
    entryRequirements: travelRestriction.entryRequirements,
  }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  if (mutationData) history.push(`/explore/travel_restrictions`)

  const performMutation = () => schema.validate(input)
    .then(_ => {
      const mutationInput = Object.entries(prepareMutationInput(defaultInput, input)).reduce((newObj, [key, value]) => {
        switch (key) {
          case 'vaccinationPolicyTranslations':
          case 'certificateAfterPolicyTranslations':
          case 'certificateBeforePolicyTranslations':
          case 'certificatePolicyTranslations':
          case 'quarantinePolicyTranslations':
          case 'insurancePolicyTranslations':
          case 'masksPolicyTranslations':
          case 'foodPolicyTranslations':
          case 'internalRestrictionsTranslations':
          case 'entryRestrictionsTranslations':
          case 'transitTranslations':
            newObj[key] = value === null || (value as { en?: { su?: string } })?.en?.su === "" ? null : preprocessInputTranslation(value)
            break

          case 'openingDate':
          case 'directAllowedDate':
            newObj[key] = value === null ? null : (value as Moment).format('YYYY-MM-DD')
            break

          case 'destinationId':
            newObj[key] = value
            newObj['destinationType'] = input.destinationType
            break

          default:
            newObj[key] = value
        }
        return newObj;
      }, {});

      return updateTravelRestriction({ variables: { input: preprocessJSONInputsGently(mutationInput), id } })
    }, e => processErrors(e, setErrors))

  const inheritComponentForField = (field, inheritField, title: string, defaultValue: any = null) => {
    const camelKey = snakeToCamel(field)
    const inheritKey = snakeToCamel(inheritField)

    return {
      component: <BoolInput
        label={`Inherit ${title}`}
        checked={input[inheritKey]}
        onChange={inherit => setInput({
          ...input,
          [inheritKey]: inherit,
          [camelKey]: inherit ? defaultValue : input[camelKey]
        })}
        latestChange={latestFieldChanges[inheritField]}
      />,
      rule: getRule(accessRules, 'travelRestriction', inheritKey)
    }
  }

  const accessRules = getRules();

  const inputComponents = {
    changelog: [
      {
        component: <Changelog
          entity='TRAVEL_RESTRICTION'
          entityId={id}
        />,
        rule: getRule(accessRules, 'query', 'monthlyChangelog')
      }
    ]
  }
  const tabList: CardTabListType[] = [
    { key: 'main', tab: 'Main' },
    { key: 'changelog', tab: 'Changelog' }
  ]
  const tabs = {
    main: <MainForm
      input={input}
      setInput={setInput}
      accessRules={accessRules}
      latestFieldChanges={latestFieldChanges}
      inheritComponentForField={inheritComponentForField}
    />,
    changelog: prepareComponents('changelog', inputComponents.changelog)
  }

  const translationsFields = [
    { key: 'vaccinationPolicyTranslations', title: 'Vaccination Policy Translations' },
    { key: 'certificateAfterPolicyTranslations', title: 'Certificate After Policy Translations' },
    { key: 'certificateBeforePolicyTranslations', title: 'Certificate Before Policy Translations' },
    { key: 'certificatePolicyTranslations', title: 'Certificate Policy Translations' },
    { key: 'quarantinePolicyTranslations', title: 'Quarantine Policy Translations' },
    { key: 'insurancePolicyTranslations', title: 'Insurance Policy Translations' },
    { key: 'masksPolicyTranslations', title: 'Masks Policy Translations' },
    { key: 'foodPolicyTranslations', title: 'Food Policy Translations' },
    { key: 'internalRestrictionsTranslations', title: 'Internal Restrictions Translations' },
    { key: 'entryRestrictionsTranslations', title: 'Entry Restrictions Translations' },
    { key: 'transitTranslations', title: 'Transit Translations' }
  ]

  translationsFields.forEach(field => {
    const { key, title } = field

    inputComponents[key] = [
      inheritComponentForField(key, `inherit_${key}`, title),
      {
        component: <TranslationsInput
          multiline
          disabled={input[`inherit${capitalize(snakeToCamel(key))}`]}
          setTranslations={translations => setInput({ ...input, [key]: translations })}
          translations={input[key]}
          localesOrder={["ru", "en"]}
        />,
        rule: getRule(accessRules, 'travelRestriction', key)
      }
    ]

    tabList.push({ key, tab: title })

    tabs[key] = prepareComponents(key, inputComponents[key])
  })

  return (
    <Card
      tabList={tabList}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        tabs[activeTab]
      }

      {
        (activeTab !== 'changelog') &&
        <>
          <Button
            style={{ float: 'left' }}
            disabled={equal(input, defaultInput)}
            onClick={() => setInput(defaultInput)}
            children='Reset Changes'
          />
          <Button
            onClick={performMutation}
            style={{ float: 'right' }}
            type='primary'
            children='Update Travel Restriction'
          />
        </>
      }
    </Card>
  )
}

const MainForm = ({ input, setInput, accessRules, latestFieldChanges, inheritComponentForField }) => {
  const { loading, error: qError, data: inputsData } = useQuery(InputsDataQ)
  const { loading: loadingRequirement, error, data: requirementData } = useQuery(InputsEntryRequirementsQ)

  if (loading || loadingRequirement) return (<Spin />)
  if (qError || error) return (<Alert message='Countries fetching error' />)

  const inputComponents = [
    {
      component: <CountryInput
        label='Passport Country'
        countries={inputsData.countries}
        value={input.passportCountryId}
        disabled={loading}
        onChange={passportCountryId => setInput({ ...input, passportCountryId })}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'passportCountry')
    },
    {
      component: <CountryInput
        label='Origin Country'
        countries={inputsData.countries}
        value={input.originCountryId}
        disabled={loading}
        onChange={originCountryId => setInput({ ...input, originCountryId })}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'originCountry')
    },
    {
      component: <PlaceInput
        label='Destination'
        onTypeChange={destinationType => setInput({ ...input, destinationType, destinationId: -1 })}
        onIdChange={destinationId => setInput({ ...input, destinationId })}
        typeValue={input.destinationType}
        idValue={input.destinationId}
        disabled={loading}
        countries={inputsData.countries}
        cities={inputsData.cities}
        airports={inputsData.airports}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'destination')
    },
    inheritComponentForField('status', 'inherit_status', 'Status'),
    {
      component:
        <Form.Item label='Status'>
          <Input.Group>
            <Select
              style={{ width: '150px' }}
              value={input.status === null ? 'null' : input.status}
              onChange={status => setInput({ ...input, status })}
              disabled={loading || input.inheritStatus}
            >
              <Select.Option key={'opened'} value={'opened'}>Opened</Select.Option>
              <Select.Option key={'partially'} value={'partially'}>Partially Opened</Select.Option>
              <Select.Option key={'closed'} value={'closed'}>Closed</Select.Option>
              <Select.Option key={'null'} value={'null'}>Unknown</Select.Option>
            </Select>
          </Input.Group>
          <LatestChange data={latestFieldChanges['status']} />
        </Form.Item>,
      rule: getRule(accessRules, 'travelRestriction', 'status')
    },
    inheritComponentForField('open', 'inherit_open', 'Open'),
    {
      component: <NullableBoolInput
        label='Open'
        value={input.open}
        disabled={loading || input.inheritOpen}
        onChange={open => setInput({ ...input, open })}
        latestChange={latestFieldChanges['open']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'open')
    },
    inheritComponentForField('opening_date', 'inherit_opening_date', 'Opening Date'),
    {
      component: <DateInput
        label='Opening Date'
        value={input.openingDate}
        disabled={loading || input.inheritOpeningDate}
        onChange={openingDate => setInput({ ...input, openingDate })}
        latestChange={latestFieldChanges['opening_date']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'openingDate')
    },
    inheritComponentForField('direct_allowed', 'inherit_direct_allowed', 'Direct Allowed'),
    {
      component: <NullableBoolInput
        label='Direct Allowed'
        value={input.directAllowed}
        disabled={loading || input.inheritDirectAllowed}
        onChange={directAllowed => setInput({ ...input, directAllowed })}
        latestChange={latestFieldChanges['direct_allowed']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'directAllowed'),
    },
    inheritComponentForField('direct_allowed_date', 'inherit_direct_allowed_date', 'Direct Allowed Date'),
    {
      component: <DateInput
        label='Direct Allowed Date'
        value={input.directAllowedDate}
        disabled={loading || input.inheritDirectAllowedDate}
        onChange={directAllowedDate => setInput({ ...input, directAllowedDate })}
        latestChange={latestFieldChanges['direct_allowed_date']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'directAllowedDate')
    },
    inheritComponentForField('vaccination_required', 'inherit_vaccination_required', 'Vaccination Required'),
    {
      component: <NullableBoolInput
        label='Vaccination Required'
        value={input.vaccinationRequired}
        disabled={loading || input.inheritVaccinationRequired}
        onChange={vaccinationRequired => setInput({ ...input, vaccinationRequired })}
        latestChange={latestFieldChanges['vaccination_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'vaccinationRequired'),
    },
    inheritComponentForField('certificate_after_required', 'inherit_certificate_after_required', 'Certificate After Required'),
    {
      component: <NullableBoolInput
        label='Certificate After Required'
        value={input.certificateAfterRequired}
        disabled={loading || input.inheritCertificateAfterRequired}
        onChange={certificateAfterRequired => setInput({ ...input, certificateAfterRequired })}
        latestChange={latestFieldChanges['certificate_after_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'certificateAfterRequired'),
    },
    inheritComponentForField('certificate_before_required', 'inherit_certificate_before_required', 'Certificate Before Required'),
    {
      component: <NullableBoolInput
        label='Certificate Before Required'
        value={input.certificateBeforeRequired}
        disabled={loading || input.inheritCertificateBeforeRequired}
        onChange={certificateBeforeRequired => setInput({ ...input, certificateBeforeRequired })}
        latestChange={latestFieldChanges['certificate_before_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'certificateBeforeRequired'),
    },
    inheritComponentForField('certificate_required', 'inherit_certificate_required', 'Certificate Required'),
    {
      component: <NullableBoolInput
        label='Certificate Required'
        value={input.certificateRequired}
        disabled={loading || input.inheritCertificateRequired}
        onChange={certificateRequired => setInput({ ...input, certificateRequired })}
        latestChange={latestFieldChanges['certificate_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'certificateRequired'),
    },
    inheritComponentForField('quarantine_required', 'inherit_quarantine_required', 'Quarantine Required'),
    {
      component: <NullableBoolInput
        label='Quarantine Required'
        value={input.quarantineRequired}
        disabled={loading || input.inheritQuarantineRequired}
        onChange={quarantineRequired => setInput({ ...input, quarantineRequired })}
        latestChange={latestFieldChanges['quarantine_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'quarantineRequired'),
    },
    inheritComponentForField('insurance_required', 'inherit_insurance_required', 'Insurance Required'),
    {
      component: <NullableBoolInput
        label='Insurance Required'
        value={input.insuranceRequired}
        disabled={loading || input.inheritInsuranceRequired}
        onChange={insuranceRequired => setInput({ ...input, insuranceRequired })}
        latestChange={latestFieldChanges['insurance_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'insuranceRequired'),
    },
    inheritComponentForField('masks_required', 'inherit_masks_required', 'Masks Required'),
    {
      component: <NullableBoolInput
        label='Masks Required'
        value={input.masksRequired}
        disabled={loading || input.inheritMasksRequired}
        onChange={masksRequired => setInput({ ...input, masksRequired })}
        latestChange={latestFieldChanges['masks_required']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'masksRequired'),
    },
    inheritComponentForField('food_open', 'inherit_food_open', 'Food Open'),
    {
      component: <NullableBoolInput
        label='Food Open'
        value={input.foodOpen}
        disabled={loading || input.inheritFoodOpen}
        onChange={foodOpen => setInput({ ...input, foodOpen })}
        latestChange={latestFieldChanges['food_open']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'foodOpen'),
    },
    inheritComponentForField('transit_interline_available', 'inherit_transit_interline_available', 'Transit Interline Available'),
    {
      component: <NullableBoolInput
        label='Transit Interline Available'
        value={input.transitInterlineAvailable}
        disabled={loading || input.inheritTransitInterlineAvailable}
        onChange={transitInterlineAvailable => setInput({ ...input, transitInterlineAvailable })}
        latestChange={latestFieldChanges['transit_interline_available']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'transitInterlineAvailable'),
    },
    inheritComponentForField('forbidden_direct_country_ids', 'inherit_forbidden_direct_countries', 'Forbidden Direct Countries', []),
    {
      component: <CountryInput
        disabled={loading || input.inheritForbiddenDirectCountries}
        label='Forbidden Direct Countries'
        onChange={forbiddenDirectCountryIds => setInput({ ...input, forbiddenDirectCountryIds })}
        value={input.forbiddenDirectCountryIds}
        countries={inputsData.countries}
        latestChange={latestFieldChanges['forbidden_direct_countries']}
        multi
      />,
      rule: getRule(accessRules, 'travelRestriction', 'forbiddenDirectCountries')
    },
    inheritComponentForField('allowed_layover_airport_ids', 'inherit_allowed_layover_airports', 'Allowed Layover Airports', []),
    {
      component: <AirportInput
        disabled={loading || input.inheritAllowedLayoverAirports}
        label='Allowed Layover Airports'
        onChange={allowedLayoverAirportIds => setInput({ ...input, allowedLayoverAirportIds })}
        value={input.allowedLayoverAirportIds}
        airports={inputsData.airports}
        latestChange={latestFieldChanges['allowed_layover_airports']}
        multi
      />,
      rule: getRule(accessRules, 'travelRestriction', 'allowedLayoverAirports')
    },
    {
      component: <StringsInput
        label='Sources'
        values={input.sources}
        disabled={loading}
        onChange={sources => setInput({ ...input, sources })}
        latestChange={latestFieldChanges['sources']}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'sources')
    },
    {
      component: <EntryRequirementsInput
        value={input.entryRequirements || 'NONE'}
        disabled={loadingRequirement}
        placeholder='entry requirements'
        onChange={entryRequirements => setInput({ ...input, entryRequirements: entryRequirements })}
        entryRequirements={requirementData.__type.enumValues}
      />,
      rule: getRule(accessRules, 'travelRestriction', 'entryRequirements')
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
