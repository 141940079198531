import gql from 'graphql-tag'

export const CreateTravelRestrictionSummaryM = gql`
mutation CreateTravelRestrictionSummary($input: CreateTravelRestrictionSummaryInput!){
   createTravelRestrictionSummary(input: $input){ id }
}
`

export const UpdateTravelRestrictionSummaryM = gql`
mutation UpdateTravelRestrictionSummary($input: UpdateTravelRestrictionSummaryInput!, $id: Int!){
   updateTravelRestrictionSummary(input: $input, id: $id){ id }
}
`
