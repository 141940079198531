import gql from 'graphql-tag'

const fields = `
id
passportCountry {
  id
  translations(filter: {locales: ["en", "ru"]})
}
destination {
  __typename
  ... on Country {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
  ... on City {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
  ... on Airport {
    id
    iata
    translations(filter: {locales: ["en"]})
  }
}
open
hasOpeningDate
directAllowed
translations(filter: {locales: ["en", "ru"]})
`

export const ListTravelRestrictionSummariesQ = gql`
query {
  travelRestrictionSummaries {
    ${fields}
  }
}
`

export const TravelRestrictionSummaryQ = gql`
query GetTravelRestrictionSummary($id: Int!) {
  travelRestrictionSummary(id: $id) {
    ${fields}
  }
}
`

export const InputsDataQ = gql`
  query {
    countries {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
    cities {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
    airports {
      id
      iata
      translations(filter: {locales: ["en"]})
    }
  }
`
