import React from 'react'
import { Link } from 'react-router-dom'
import { ListTravelRestrictionSummariesQ } from './queries'
import { Place, Country, Translations } from '../../../../types'
import IndexList from '../../../shared/index_list'
import ActiveBadge from '../../../shared/active_badge'
import { prepareComponents } from '../../../../utils'
import { getRule, getRules, notAllowedToEdit, notAllowedToShow } from '../../../../access_rules'
import Translation from "../../../shared/types/Translation";
import { Alert } from 'antd'
interface ListTravelRestrictionSummariesResponse {
  id: number
  passportCountry: Country
  destination?: Place
  open?: boolean
  hasOpeningDate?: boolean
  directAllowed?: boolean
  translations?: Translations
}

interface ListTravelRestrictionSummariesTableRow {
  id: number
  passportCountry: Country
  destination?: Place
  open?: boolean
  hasOpeningDate?: boolean
  directAllowed?: boolean
  translations?: Translations
}

const responseToTable = (data: Array<ListTravelRestrictionSummariesResponse>): Array<ListTravelRestrictionSummariesTableRow> => {
  return data.map(({
    id,
    passportCountry,
    destination,
    open,
    hasOpeningDate,
    directAllowed,
    translations
  }) => ({
    id,
    passportCountry,
    destination,
    open,
    hasOpeningDate,
    directAllowed,
    translations
  }))
}

export default (props: any) => {
  const accessRules = getRules()

  const createdTravelRestrictionSummaryId = props.location?.state?.createdId

  const columns: Array<object> = [
    {
      title: 'Passport Country',
      dataIndex: 'passportCountry',
      name: 'passportCountry',
      key: 'passportCountry',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'passportCountry'),
      render: ({ id, iata, translations }: Country) =>
        <Link to={`/countries/${id}`}>{translations ? translations.en.su : iata}</Link>
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      name: 'destination',
      key: 'destination',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'destination'),
      render: (destination?: Place) => {
        if (!destination) {
          return <span>No data</span>
        }

        let link: string
        switch (destination.__typename) {
          case "Country":
            link = `/countries/${destination.id}`
            break
          case "City":
            link = `/cities/${destination.id}`
            break
          case "Airport":
            link = `/airports/${destination.id}`
            break
          default:
            return
        }

        return <span>
          <Link to={link}>
            {destination.translations ? destination.translations.en.su : destination.iata}
          </Link> ({destination.__typename})
        </span>
      }
    },
    {
      title: 'Open',
      dataIndex: 'open',
      name: 'open',
      key: 'open',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'open'),
      render: (open?: boolean) =>
        open == null ? <span>No data</span> : <ActiveBadge isActive={open} activeText='opened' inactiveText='closed' />
    },
    {
      title: 'Has Opening Date',
      dataIndex: 'hasOpeningDate',
      name: 'hasOpeningDate',
      key: 'hasOpeningDate',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'hasOpeningDate'),
      render: (hasOpeningDate?: boolean) =>
        hasOpeningDate == null ? <span>No data</span> : <ActiveBadge isActive={hasOpeningDate} activeText='has open date' inactiveText='no open date' />
    },
    {
      title: 'Direct Allowed',
      dataIndex: 'directAllowed',
      name: 'directAllowed',
      key: 'directAllowed',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'directAllowed'),
      render: (directAllowed?: boolean) =>
        directAllowed == null ? <span>No data</span> : <ActiveBadge isActive={directAllowed} activeText='allowed' inactiveText='not allowed' />
    },
    {
      title: 'Translations',
      dataIndex: 'translations',
      name: 'translations',
      key: 'translations',
      hidden: notAllowedToShow(accessRules, 'travelRestrictionSummary', 'translations'),
      render: (translations?: Translations) =>
        <Translation translations={translations} locales={["ru", "en"]} />
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      fixed: 'right',
      hidden: notAllowedToEdit(accessRules, 'travelRestrictionSummary'),
      render: (id: number) =>
        <span><Link to={`/explore/travel_restrictions/summaries/${id}`}>Edit</Link></span>,
    }
  ]

  return (
    <>
      {createdTravelRestrictionSummaryId && <Alert closable type='success' message={`Travel restriction summary with id ${createdTravelRestrictionSummaryId} created successfully`}></Alert>}
      {
        prepareComponents('travel-restriction-summaries-list', [{
          component: <IndexList
            request={{ query: ListTravelRestrictionSummariesQ, datakey: 'travelRestrictionSummaries', transform: responseToTable }}
            columns={columns}
            title='Travel Restriction Summaries'
            rowKey='id'
            newEntityPath='/explore/travel_restrictions/summaries/new'
            showCreateButton={accessRules['__all_mutations']}
            filterFields={[
              { key: 'passportCountry', path: 'passportCountry.translations' },
            ]}
          />,
          rule: getRule(accessRules, 'query', 'travelRestrictionSummaries')
        }])
      }
    </>
  )
}
