import React  from 'react'
import {Form, Input, Select} from 'antd'
import { Translations } from '../../../types';
import LatestChange, {Data as LatestChangeData} from '../latest_change'
import CountryInput from "./country";
import CityInput from "./city";
import AirportInput from "./airport";
import PlaceType from "../types/place";

interface PlaceSelectProps {
  label?: string
  onTypeChange: (type: PlaceType) => void
  onIdChange: (id: any) => void
  typeValue?: PlaceType
  idValue?: number
  disabled: boolean
  countries: Array<{id: number, iata: string, translations: Translations}>
  cities: Array<{id: number, iata: string, translations: Translations}>
  airports: Array<{id: number, iata: string, translations: Translations}>
  latestChange?: LatestChangeData
}

export default ({label, disabled, countries, cities, airports, typeValue, idValue, onTypeChange, onIdChange, latestChange}: PlaceSelectProps) => {
  return (
    <Form.Item label={label ? label : 'Place'}>
      <Input.Group>
        <Select
          style={{width: '100px'}}
          value={typeValue}
          onChange={onTypeChange}
        >
          <Select.Option key={'country'} value={'COUNTRY'}>Country</Select.Option>
          <Select.Option key={'city'} value={'CITY'}>City</Select.Option>
          <Select.Option key={'airport'} value={'AIRPORT'}>Airport</Select.Option>
        </Select>

        {{
          'COUNTRY': <CountryInput
            formItemDisabled
            countries={countries}
            value={idValue}
            disabled={disabled}
            onChange={onIdChange}
          />,
          'CITY': <CityInput
            formItemDisabled
            cities={cities}
            value={idValue}
            disabled={disabled}
            onChange={onIdChange}
          />,
          'AIRPORT': <AirportInput
            formItemDisabled
            airports={airports}
            value={idValue}
            disabled={disabled}
            onChange={onIdChange}
          />
        }[typeValue || 'COUNTRY']}
      </Input.Group>
      <LatestChange data={latestChange} />
    </Form.Item>
  )
}


