import gql from 'graphql-tag'

export const CreateTravelRestrictionM = gql`
mutation CreateTravelRestriction($input: CreateTravelRestrictionInput!){
   createTravelRestriction(input: $input){ id }
}
`

export const UpdateTravelRestrictionM = gql`
mutation UpdateTravelRestriction($input: UpdateTravelRestrictionInput!, $id: Int!){
   updateTravelRestriction(input: $input, id: $id){ id }
}
`
