import React from 'react'
import {Form, Radio} from 'antd'
import LatestChange, {Data as LatestChangeData} from '../latest_change'

interface NullableBoolInputProps {
  disabled?: boolean
  onChange?: (value: boolean | null) => void,
  value?: boolean | null,
  label?: string
  latestChange?: LatestChangeData
}

export default ({disabled, onChange, label, value, latestChange}: NullableBoolInputProps) => {
  const nullableOnChange = (e) => onChange && onChange(e.target.value === 'null' ? null : e.target.value as boolean)

  return (
    <Form.Item label={label}>
      <Radio.Group disabled={disabled} onChange={nullableOnChange} value={value === null ? 'null' : value}>
        <Radio value={true}>True</Radio>
        <Radio value={false}>False</Radio>
        <Radio value={'null'}>Unknown</Radio>
      </Radio.Group>
      <LatestChange data={latestChange} />
    </Form.Item>
  )
}
