import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import App from './App'

import Airlines from './features/airlines'
import Airports from './features/places/airports'
import Cities from './features/places/cities'
import Countries from './features/places/countries'
import Languages from './features/i18n/languages'
import Locales from './features/i18n/locales'
import Locations from './features/locations'
import Markets from './features/markets'
import Metropolises from './features/places/metropolises'
import VisaRequirements from './features/visa_requirements'

import TravelRestrictions from './features/explore/travel_restrictions'
import TravelRestrictionSummaries from './features/explore/travel_restrictions/summaries'

export default () =>
  <Router basename='/admin'>
    <App>
      <Switch>
        <Redirect exact={true} from='/' to='/airports' />

        <Route path='/airports' component={Airports} />
        <Route path='/cities' component={Cities} />
        <Route path='/countries' component={Countries} />
        <Route path='/metropolises' component={Metropolises} />
        <Route path='/locations' component={Locations} />
        <Route path='/airlines' component={Airlines} />
        <Route path='/markets' component={Markets} />
        <Route path='/locales' component={Locales} />
        <Route path='/languages' component={Languages} />
        <Route path='/visa_requirements' component={VisaRequirements} />
        <Route path='/explore/travel_restrictions/summaries' component={TravelRestrictionSummaries} />
        <Route path='/explore/travel_restrictions' component={TravelRestrictions} />
      </Switch>
    </App>
  </Router>
