import React, { useState } from 'react'
import { useMutation } from '../../../mutation'
import { useQuery } from '../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin, Select, Input, Form } from 'antd'
import * as yup from 'yup'

import { CreateTravelRestrictionM } from './mutations'
import { Translations } from '../../../types'
import { InputsDataQ, InputsEntryRequirementsQ } from './queries'

import TranslationsInput from '../../shared/translations'
import DateInput from '../../shared/inputs/date'

import '../../shared/form.css'

import { processErrors, preprocessInputTranslation, snakeToCamel, capitalize } from '../../../utils'
import moment from 'moment'
import { CardTabListType } from "antd/lib/card";
import CountryInput from "../../shared/inputs/country";
import AirportInput from "../../shared/inputs/airport";
import NullableBoolInput from "../../shared/inputs/nullable_bool";
import StringsInput from "../../shared/inputs/strings";
import PlaceInput from "../../shared/inputs/place"
import PlaceType from "../../shared/types/place";
import BoolInput from "../../shared/inputs/bool";
import EntryRequirementsInput from "./inputs/entry_requirements";

interface CreateTravelRestrictionInput {
  passportCountryId?: number
  originCountryId?: number
  destinationType?: PlaceType
  destinationId?: number
  status?: string | null
  inheritStatus: boolean
  open?: boolean | null
  inheritOpen: boolean
  openingDate?: Date | null
  inheritOpeningDate: boolean
  directAllowed?: boolean | null
  inheritDirectAllowed: boolean
  directAllowedDate?: Date | null
  inheritDirectAllowedDate: boolean
  vaccinationRequired?: boolean | null
  inheritVaccinationRequired: boolean
  vaccinationPolicyTranslations?: Translations | null
  inheritVaccinationPolicyTranslations: boolean
  certificateAfterRequired?: boolean | null
  inheritCertificateAfterRequired: boolean
  certificateAfterPolicyTranslations?: Translations | null
  inheritCertificateAfterPolicyTranslations: boolean
  certificateBeforeRequired?: boolean | null
  inheritCertificateBeforeRequired: boolean
  certificateBeforePolicyTranslations?: Translations | null
  inheritCertificateBeforePolicyTranslations: boolean
  certificateRequired?: boolean | null
  inheritCertificateRequired: boolean
  certificatePolicyTranslations?: Translations | null
  inheritCertificatePolicyTranslations: boolean
  quarantineRequired?: boolean | null
  inheritQuarantineRequired: boolean
  quarantinePolicyTranslations?: Translations | null
  inheritQuarantinePolicyTranslations: boolean
  insuranceRequired?: boolean | null
  inheritInsuranceRequired: boolean
  insurancePolicyTranslations?: Translations | null
  inheritInsurancePolicyTranslations: boolean
  masksRequired?: boolean | null
  inheritMasksRequired: boolean
  masksPolicyTranslations?: Translations | null
  inheritMasksPolicyTranslations: boolean
  foodOpen?: boolean | null
  inheritFoodOpen: boolean
  foodPolicyTranslations?: Translations | null
  inheritFoodPolicyTranslations: boolean
  sources?: string[]
  internalRestrictionsTranslations?: Translations | null
  inheritInternalRestrictionsTranslations: boolean
  entryRestrictionsTranslations?: Translations | null
  inheritEntryRestrictionsTranslations: boolean
  forbiddenDirectCountryIds: number[]
  inheritForbiddenDirectCountries: boolean
  allowedLayoverAirportIds: number[]
  inheritAllowedLayoverAirports: boolean
  transitInterlineAvailable?: boolean | null
  inheritTransitInterlineAvailable: boolean
  transitTranslations?: Translations | null
  inheritTransitTranslations: boolean,
  entryRequirements: string
}

export const schema = yup.object().shape({
  passportCountryId: yup.number().min(1).required(),
  originCountryId: yup.number().min(1).required(),
  destinationType: yup.string().required(),
  destinationId: yup.number().min(1).required(),
  status: yup.string().nullable(),
  inheritStatus: yup.boolean().required(),
  open: yup.boolean().nullable(),
  inheritOpen: yup.boolean().required(),
  openingDate: yup.date().nullable(),
  inheritOpeningDate: yup.boolean().required(),
  directAllowed: yup.boolean().nullable(),
  inheritDirectAllowed: yup.boolean().required(),
  directAllowedDate: yup.date().nullable(),
  inheritDirectAllowedDate: yup.boolean().required(),
  certificateAfterRequired: yup.boolean().nullable(),
  inheritCertificateAfterRequired: yup.boolean().required(),
  vaccinationRequired: yup.boolean().nullable(),
  inheritVaccinationRequired: yup.boolean().required(),
  vaccinationPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritVaccinationPolicyTranslations: yup.boolean().required(),
  certificateAfterPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificateAfterPolicyTranslations: yup.boolean().required(),
  certificateBeforeRequired: yup.boolean().nullable(),
  inheritCertificateBeforeRequired: yup.boolean().required(),
  certificateBeforePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificateBeforePolicyTranslations: yup.boolean().required(),
  certificateRequired: yup.boolean().nullable(),
  inheritCertificateRequired: yup.boolean().required(),
  certificatePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritCertificatePolicyTranslations: yup.boolean().required(),
  quarantineRequired: yup.boolean().nullable(),
  inheritQuarantineRequired: yup.boolean().required(),
  quarantinePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritQuarantinePolicyTranslations: yup.boolean().required(),
  insuranceRequired: yup.boolean().nullable(),
  inheritInsuranceRequired: yup.boolean().required(),
  insurancePolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritInsurancePolicyTranslations: yup.boolean().required(),
  masksRequired: yup.boolean().nullable(),
  inheritMasksRequired: yup.boolean().required(),
  masksPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritMasksPolicyTranslations: yup.boolean().required(),
  foodOpen: yup.boolean().nullable(),
  inheritFoodOpen: yup.boolean().required(),
  foodPolicyTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritFoodPolicyTranslations: yup.boolean().required(),
  sources: yup.array().of(yup.string()),
  internalRestrictionsTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritInternalRestrictionsTranslations: yup.boolean().required(),
  entryRestrictionsTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritEntryRestrictionsTranslations: yup.boolean().required(),
  forbiddenDirectCountryIds: yup.array().of(yup.number().min(1)),
  inheritForbiddenDirectCountries: yup.boolean().required(),
  allowedLayoverAirportIds: yup.array().of(yup.number().min(1)),
  inheritAllowedLayoverAirports: yup.boolean().required(),
  transitInterlineAvailable: yup.boolean().nullable(),
  inheritTransitInterlineAvailable: yup.boolean().required(),
  transitTranslations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable(),
  inheritTransitTranslations: yup.boolean().required(),
  entryRequirements: yup.string().required()
})

const defaultInput: CreateTravelRestrictionInput = {
  passportCountryId: -1,
  originCountryId: -1,
  destinationType: 'COUNTRY',
  destinationId: -1,
  status: null,
  inheritStatus: false,
  open: null,
  inheritOpen: false,
  openingDate: null,
  inheritOpeningDate: false,
  directAllowed: null,
  inheritDirectAllowed: false,
  directAllowedDate: null,
  inheritDirectAllowedDate: false,
  vaccinationRequired: null,
  inheritVaccinationRequired: false,
  vaccinationPolicyTranslations: null,
  inheritVaccinationPolicyTranslations: false,
  certificateAfterRequired: null,
  inheritCertificateAfterRequired: false,
  certificateAfterPolicyTranslations: null,
  inheritCertificateAfterPolicyTranslations: false,
  certificateBeforeRequired: null,
  inheritCertificateBeforeRequired: false,
  certificateBeforePolicyTranslations: null,
  inheritCertificateBeforePolicyTranslations: false,
  certificateRequired: null,
  inheritCertificateRequired: false,
  certificatePolicyTranslations: null,
  inheritCertificatePolicyTranslations: false,
  quarantineRequired: null,
  inheritQuarantineRequired: false,
  quarantinePolicyTranslations: null,
  inheritQuarantinePolicyTranslations: false,
  insuranceRequired: null,
  inheritInsuranceRequired: false,
  insurancePolicyTranslations: null,
  inheritInsurancePolicyTranslations: false,
  masksRequired: null,
  inheritMasksRequired: false,
  masksPolicyTranslations: null,
  inheritMasksPolicyTranslations: false,
  foodOpen: null,
  inheritFoodOpen: false,
  foodPolicyTranslations: null,
  inheritFoodPolicyTranslations: false,
  sources: [],
  internalRestrictionsTranslations: null,
  inheritInternalRestrictionsTranslations: false,
  entryRestrictionsTranslations: null,
  inheritEntryRestrictionsTranslations: false,
  forbiddenDirectCountryIds: [],
  inheritForbiddenDirectCountries: false,
  allowedLayoverAirportIds: [],
  inheritAllowedLayoverAirports: false,
  transitInterlineAvailable: null,
  inheritTransitInterlineAvailable: false,
  transitTranslations: null,
  inheritTransitTranslations: false,
  entryRequirements: 'NONE'
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateTravelRestrictionInput>(defaultInput)
  const [createTravelRestriction, { data: mutationData }] = useMutation(CreateTravelRestrictionM, setErrors)

  const preprocessTranslations = (translations) =>
    translations === null || (translations as { en?: { su?: string } })?.en?.su === "" ? null : preprocessInputTranslation(translations)

  const performMutation = () => {
    schema.validate(input)
      .then(_ => createTravelRestriction({
        variables: {
          input: {
            ...input,
            openingDate: input.openingDate === null ? null : moment(input.openingDate).format('YYYY-MM-DD'),
            certificateAfterPolicyTranslations: preprocessTranslations(input.certificateAfterPolicyTranslations),
            vaccinationPolicyTranslations: preprocessTranslations(input.vaccinationPolicyTranslations),
            certificateBeforePolicyTranslations: preprocessTranslations(input.certificateBeforePolicyTranslations),
            certificatePolicyTranslations: preprocessTranslations(input.certificatePolicyTranslations),
            quarantinePolicyTranslations: preprocessTranslations(input.quarantinePolicyTranslations),
            insurancePolicyTranslations: preprocessTranslations(input.insurancePolicyTranslations),
            masksPolicyTranslations: preprocessTranslations(input.masksPolicyTranslations),
            foodPolicyTranslations: preprocessTranslations(input.foodPolicyTranslations),
            internalRestrictionsTranslations: preprocessTranslations(input.internalRestrictionsTranslations),
            entryRestrictionsTranslations: preprocessTranslations(input.entryRestrictionsTranslations),
            transitTranslations: preprocessTranslations(input.transitTranslations)
          }
        }
      }), e => processErrors(e, setErrors))
  }

  if (mutationData) history.push({
    pathname: `/explore/travel_restrictions`,
    state: { createdId: mutationData.createTravelRestriction.id }
  })

  const inheritComponentForField = (field, inheritField, title: string, defaultValue: any = null) => {
      const camelKey = snakeToCamel(field)
      const inheritKey = snakeToCamel(inheritField)

      return <BoolInput
        label={`Inherit ${title}`}
        checked={input[inheritKey]}
        onChange={inherit => setInput({
          ...input,
          [inheritKey]: inherit,
          [camelKey]: inherit ? defaultValue : input[camelKey]
        })}
      />
    }

  const tabList: CardTabListType[] = [{ key: 'main', tab: 'Main' }]
  const tabs = {
    main: <MainForm
      input={input}
      setInput={setInput}
      inheritComponentForField={inheritComponentForField}
    />
  }

  const translationsFields = [
    { key: 'vaccinationPolicyTranslations', title: 'Vaccination Policy Translations' },
    { key: 'certificateAfterPolicyTranslations', title: 'Certificate After Policy Translations' },
    { key: 'certificateBeforePolicyTranslations', title: 'Certificate Before Policy Translations' },
    { key: 'certificatePolicyTranslations', title: 'Certificate Policy Translations' },
    { key: 'quarantinePolicyTranslations', title: 'Quarantine Policy Translations' },
    { key: 'insurancePolicyTranslations', title: 'Insurance Policy Translations' },
    { key: 'masksPolicyTranslations', title: 'Masks Policy Translations' },
    { key: 'foodPolicyTranslations', title: 'Food Policy Translations' },
    { key: 'internalRestrictionsTranslations', title: 'Internal Translations' },
    { key: 'entryRestrictionsTranslations', title: 'Entry Translations' },
    { key: 'transitTranslations', title: 'Transit Translations' }
  ]

  translationsFields.forEach(field => {
    const { key, title } = field

    tabList.push({ key, tab: title })

    tabs[key] = [
      inheritComponentForField(key, `inherit_${key}`, title, null),
      <TranslationsInput
        multiline
        disabled={input[`inherit${capitalize(snakeToCamel(key))}`]}
        setTranslations={translations => setInput({ ...input, [key]: translations })}
        translations={input[key]}
      />
    ]
  })

  return (
    <Card
      tabList={tabList}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        tabs[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />

    </Card>
  )
}
const MainForm = ({ input, setInput, inheritComponentForField }) => {
  const { loading, error: qError, data: inputsData } = useQuery(InputsDataQ)
  const { data: requirementData, error, loading: loadingRequirement } = useQuery(InputsEntryRequirementsQ)

  if (loading || loadingRequirement) return (<Spin />)
  if (qError || error) return (<Alert message='Countries fetching error' />)

  return (
    <>
      <CountryInput
        label='Passport Country'
        countries={inputsData.countries}
        value={input.passportCountryId}
        disabled={loading}
        onChange={passportCountryId => setInput({ ...input, passportCountryId })}
      />

      <CountryInput
        label='Origin Country'
        countries={inputsData.countries}
        value={input.originCountryId}
        disabled={loading}
        onChange={originCountryId => setInput({ ...input, originCountryId })}
      />

      <PlaceInput
        label='Destination'
        onTypeChange={destinationType => setInput({ ...input, destinationType, destinationId: -1 })}
        onIdChange={destinationId => setInput({ ...input, destinationId })}
        typeValue={input.destinationType}
        idValue={input.destinationId}
        disabled={loading}
        countries={inputsData.countries}
        cities={inputsData.cities}
        airports={inputsData.airports}
      />

      {inheritComponentForField('status', 'inherit_status', 'Status')}

      <Form.Item label='Status'>
        <Input.Group>
          <Select
            style={{ width: '150px' }}
            value={input.status === null ? 'null' : input.status}
            onChange={status => setInput({ ...input, status })}
            disabled={loading || input.inheritStatus}
          >
            <Select.Option key={'opened'} value={'opened'}>Opened</Select.Option>
            <Select.Option key={'partially'} value={'partially'}>Partially Opened</Select.Option>
            <Select.Option key={'closed'} value={'closed'}>Closed</Select.Option>
            <Select.Option key={'null'} value={'null'}>Unknown</Select.Option>
          </Select>
        </Input.Group>
      </Form.Item>

      {inheritComponentForField('open', 'inherit_open', 'Open')}

      <NullableBoolInput
        label='Open'
        value={input.open}
        disabled={loading || input.inheritOpen}
        onChange={open => setInput({ ...input, open })}
      />

      {inheritComponentForField('opening_date', 'inherit_opening_date', 'Opening Date')}

      <DateInput
        label='Opening Date'
        value={input.openingDate}
        disabled={loading || input.inheritOpeningDate}
        onChange={openingDate => setInput({ ...input, openingDate })}
      />

      {inheritComponentForField('direct_allowed', 'inherit_direct_allowed', 'Direct Allowed')}

      <NullableBoolInput
        label='Direct Allowed'
        value={input.directAllowed}
        disabled={loading || input.inheritDirectAllowed}
        onChange={directAllowed => setInput({ ...input, directAllowed })}
      />

      {inheritComponentForField('direct_allowed_date', 'inherit_direct_allowed_date', 'Direct Allowed Date')}

      <DateInput
        label='Direct Allowed Date'
        value={input.directAllowedDate}
        disabled={loading || input.inheritDirectAllowedDate}
        onChange={directAllowedDate => setInput({ ...input, directAllowedDate })}
      />

      {inheritComponentForField('vaccination_required', 'inherit_vaccination_required', 'Vaccination Required')}

      <NullableBoolInput
        label='Vaccination Required'
        value={input.vaccinationRequired}
        disabled={loading || input.inheritVaccinationRequired}
        onChange={vaccinationRequired => setInput({ ...input, vaccinationRequired })}
      />

      {inheritComponentForField('certificate_after_required', 'inherit_certificate_after_required', 'Certificate After Required')}

      <NullableBoolInput
        label='Certificate After Required'
        value={input.certificateAfterRequired}
        disabled={loading || input.inheritCertificateAfterRequired}
        onChange={certificateAfterRequired => setInput({ ...input, certificateAfterRequired })}
      />

      {inheritComponentForField('certificate_before_required', 'inherit_certificate_before_required', 'Certificate Before Required')}

      <NullableBoolInput
        label='Certificate Before Required'
        value={input.certificateBeforeRequired}
        disabled={loading || input.inheritCertificateBeforeRequired}
        onChange={certificateBeforeRequired => setInput({ ...input, certificateBeforeRequired })}
      />

      {inheritComponentForField('certificate_required', 'inherit_certificate_required', 'Certificate Required')}

      <NullableBoolInput
        label='Certificate Required'
        value={input.certificateRequired}
        disabled={loading || input.inheritCertificateRequired}
        onChange={certificateRequired => setInput({ ...input, certificateRequired })}
      />

      {inheritComponentForField('quarantine_required', 'inherit_quarantine_required', 'Quarantine Required')}

      <NullableBoolInput
        label='Quarantine Required'
        value={input.quarantineRequired}
        disabled={loading || input.inheritQuarantineRequired}
        onChange={quarantineRequired => setInput({ ...input, quarantineRequired })}
      />

      {inheritComponentForField('insurance_required', 'inherit_insurance_required', 'Insurance Required')}

      <NullableBoolInput
        label='Insurance Required'
        value={input.insuranceRequired}
        disabled={loading || input.inheritInsuranceRequired}
        onChange={insuranceRequired => setInput({ ...input, insuranceRequired })}
      />

      {inheritComponentForField('masks_required', 'inherit_masks_required', 'Masks Required')}

      <NullableBoolInput
        label='Masks Required'
        value={input.masksRequired}
        disabled={loading || input.inheritMasksRequired}
        onChange={masksRequired => setInput({ ...input, masksRequired })}
      />

      {inheritComponentForField('food_open', 'inherit_food_open', 'Food Open')}

      <NullableBoolInput
        label='Food Open'
        value={input.foodOpen}
        disabled={loading || input.inheritFoodOpen}
        onChange={foodOpen => setInput({ ...input, foodOpen })}
      />

      {inheritComponentForField('transit_interline_available', 'inherit_transit_interline_available', 'Transit Interline Available')}

      <NullableBoolInput
        label='Transit Interline Available'
        value={input.transitInterlineAvailable}
        disabled={loading || input.inheritTransitInterlineAvailable}
        onChange={transitInterlineAvailable => setInput({ ...input, transitInterlineAvailable })}
      />

      {inheritComponentForField('forbidden_direct_country_ids', 'inherit_forbidden_direct_countries', 'Forbidden Direct Countries', [])}

      <CountryInput
        disabled={loading || input.inheritForbiddenDirectCountries}
        label='Forbidden Direct Countries'
        onChange={forbiddenDirectCountryIds => setInput({ ...input, forbiddenDirectCountryIds })}
        value={input.forbiddenDirectCountryIds}
        countries={inputsData.countries}
        multi
      />

      {inheritComponentForField('allowed_layover_airport_ids', 'inherit_allowed_layover_airports', 'Allowed Layover Airports', [])}

      <AirportInput
        disabled={loading || input.inheritAllowedLayoverAirports}
        label='Allowed Layover Airports'
        onChange={allowedLayoverAirportIds => setInput({ ...input, allowedLayoverAirportIds })}
        value={input.allowedLayoverAirportIds}
        airports={inputsData.airports}
        multi
      />

      <StringsInput
        label='Sources'
        values={input.sources}
        disabled={loading}
        onChange={sources => setInput({ ...input, sources })}
      />

      <EntryRequirementsInput
        value={input.entryRequirements || 'NONE'}
        disabled={loadingRequirement}
        placeholder='entry requirements'
        onChange={entryRequirements => setInput({ ...input, entryRequirements: entryRequirements })}
        entryRequirements={requirementData.__type.enumValues}
      />
    </>
  )
}
