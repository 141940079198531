import React from 'react'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import { getRule, getRules } from './access_rules'
const { SubMenu, Item } = Menu

export default () => {
  const accessRules = getRules();

  const showAirportsItem = getRule(accessRules, 'query', 'airports') !== 'disallow'
  const showCitiesItem = getRule(accessRules, 'query', 'cities') !== 'disallow'
  const showCountriesItem = getRule(accessRules, 'query', 'countries') !== 'disallow'
  const showMetropolisesItem = getRule(accessRules, 'query', 'metropolises') !== 'disallow'
  const showPlacesItem = showAirportsItem || showCitiesItem || showCountriesItem || showMetropolisesItem
  const showLocationsItem = getRule(accessRules, 'query', 'locations') !== 'disallow'
  const showLocalesItem = getRule(accessRules, 'query', 'locales') !== 'disallow'
  const showLanguagesItem = getRule(accessRules, 'query', 'languages') !== 'disallow'
  const showI18Item = showLocalesItem || showLanguagesItem
  const showAirlinesItem = getRule(accessRules, 'query', 'airlines') !== 'disallow'
  const showMarketsItem = getRule(accessRules, 'query', 'markets') !== 'disallow'
  const showVisaRequirementsItem = getRule(accessRules, 'query', 'visaRequirements') !== 'disallow'
  const showTravelRestrictionsItem = getRule(accessRules, 'query', 'travelRestrictions') !== 'disallow'
  const showTravelRestrictionSummariesItem = getRule(accessRules, 'query', 'travelRestrictionSummaries') !== 'disallow'

  return (
    <Menu
      theme='dark'
      mode='horizontal'
      style={{ lineHeight: '64px' }}
    >
      {showPlacesItem && <SubMenu key='places' title={'Places'}>
        {showAirportsItem && <Item key='places:airports'>
          <NavLink to='/airports'>Airports</NavLink>
        </Item>}

        {showCitiesItem && <Item key='places:cities'>
          <NavLink to='/cities'>Cities</NavLink>
        </Item>}

        {showCountriesItem && <Item key='places:countries'>
          <NavLink to='/countries'>Countries</NavLink>
        </Item>}

        {showMetropolisesItem && <Item key='places:metropolises'>
          <NavLink to='/metropolises'>Metropolises</NavLink>
        </Item>}
      </SubMenu>}

      {showLocationsItem && <Item key='locations'>
        <NavLink to='/locations'>Locations</NavLink>
      </Item>}


      {showI18Item && <SubMenu key='i18n' title={'i18n'}>
        {showLocalesItem && <Item key='locales'>
          <NavLink to='/locales'>Locales</NavLink>
        </Item>}

        {showLanguagesItem && <Item key='languages'>
          <NavLink to='/languages'>Languages</NavLink>
        </Item>}
      </SubMenu>}

      {showAirlinesItem && <Item key='airlines'>
        <NavLink to='/airlines'>Airlines</NavLink>
      </Item>}

      {showMarketsItem && <Item key='markets'>
        <NavLink to='/markets'>Markets</NavLink>
      </Item>}

      {showVisaRequirementsItem && <Item key='visa_requirements'>
        <NavLink to='/visa_requirements'>Visa Requirements</NavLink>
      </Item>}

      {
        (
          showTravelRestrictionsItem ||
          showTravelRestrictionSummariesItem
        ) &&
        <SubMenu key='explore' title='Explore'>
          {showTravelRestrictionsItem && <Item key='travel_restrictions'>
            <NavLink to='/explore/travel_restrictions'>Travel Restrictions</NavLink>
          </Item>}
          {showTravelRestrictionSummariesItem && <Item key='travel_restriction_summaries'>
            <NavLink to='/explore/travel_restrictions/summaries'>Travel Restriction Summaries</NavLink>
          </Item>}
        </SubMenu>
      }
    </Menu>
  )
}
