import React from 'react'
import { Link } from 'react-router-dom'
import { ListTravelRestrictionsQ } from './queries'
import { Place, Country, Translations, Airport } from '../../../types'
import IndexList from '../../shared/index_list'
import { prepareComponents } from '../../../utils'
import { notAllowedToEdit, getRule, getRules, notAllowedToShow } from '../../../access_rules'
import { Alert, Switch } from "antd";
import Translation from "../../shared/types/Translation";
import moment from "moment";

const inheritPlaceholder = 'INHERIT'
type Inherit = 'INHERIT'

interface ListTravelRestrictionsResponse {
  id: number
  passportCountry: Country
  originCountry: Country
  destination: Place
  status?: string
  inheritStatus: boolean
  open?: boolean
  inheritOpen: boolean
  openingDate?: Date
  inheritOpeningDate: boolean
  directAllowed?: boolean
  inheritDirectAllowed: boolean
  directAllowedDate?: Date
  inheritDirectAllowedDate: boolean
  vaccinationRequired?: boolean
  inheritVaccinationRequired: boolean
  vaccinationPolicyTranslations?: Translations
  inheritVaccinationPolicyTranslations: boolean
  certificateAfterRequired?: boolean
  inheritCertificateAfterRequired: boolean
  certificateAfterPolicyTranslations?: Translations
  inheritCertificateAfterPolicyTranslations: boolean
  certificateBeforeRequired?: boolean
  inheritCertificateBeforeRequired: boolean
  certificateBeforePolicyTranslations?: Translations
  inheritCertificateBeforePolicyTranslations: boolean
  certificateRequired?: boolean
  inheritCertificateRequired: boolean
  certificatePolicyTranslations?: Translations
  inheritCertificatePolicyTranslations: boolean
  quarantineRequired?: boolean
  inheritQuarantineRequired: boolean
  quarantinePolicyTranslations?: Translations
  inheritQuarantinePolicyTranslations: boolean
  insuranceRequired?: boolean
  inheritInsuranceRequired: boolean
  insurancePolicyTranslations?: Translations
  inheritInsurancePolicyTranslations: boolean
  masksRequired?: boolean
  inheritMasksRequired: boolean
  masksPolicyTranslations?: Translations
  inheritMasksPolicyTranslations: boolean
  foodOpen?: boolean
  inheritFoodOpen: boolean
  foodPolicyTranslations?: Translations
  inheritFoodPolicyTranslations: boolean
  sources: string[]
  internalRestrictionsTranslations?: Translations
  inheritInternalRestrictionsTranslations: boolean
  entryRestrictionsTranslations?: Translations
  inheritEntryRestrictionsTranslations: boolean
  forbiddenDirectCountries: Country[]
  inheritForbiddenDirectCountries: boolean
  allowedLayoverAirports: Airport[]
  inheritAllowedLayoverAirports: boolean
  transitInterlineAvailable?: boolean
  inheritTransitInterlineAvailable: boolean
  transitTranslations?: Translations
  inheritTransitTranslations: boolean
  lastModified: string
}

interface ListTravelRestrictionsTableRow {
  id: number
  passportCountry: Country
  originCountry: Country
  destination: Place
  status?: string | Inherit
  open?: boolean | Inherit
  openingDate?: Date | Inherit
  directAllowed?: boolean | Inherit
  directAllowedDate?: Date | Inherit
  vaccinationRequired?: boolean | Inherit
  vaccinationPolicyTranslations?: Translations | Inherit
  certificateAfterRequired?: boolean | Inherit
  certificateAfterPolicyTranslations?: Translations | Inherit
  certificateBeforeRequired?: boolean | Inherit
  certificateBeforePolicyTranslations?: Translations | Inherit
  certificateRequired?: boolean | Inherit
  certificatePolicyTranslations?: Translations | Inherit
  quarantineRequired?: boolean | Inherit
  quarantinePolicyTranslations?: Translations | Inherit
  insuranceRequired?: boolean | Inherit
  insurancePolicyTranslations?: Translations | Inherit
  masksRequired?: boolean | Inherit
  masksPolicyTranslations?: Translations | Inherit
  foodOpen?: boolean | Inherit
  foodPolicyTranslations?: Translations | Inherit
  sources: string[]
  internalRestrictionsTranslations?: Translations | Inherit
  entryRestrictionsTranslations?: Translations | Inherit
  forbiddenDirectCountries: Country[] | Inherit
  allowedLayoverAirports: Airport[] | Inherit
  transitInterlineAvailable?: boolean | Inherit,
  transitTranslations?: Translations | Inherit
  lastModified: moment.Moment
}

const responseToTable = (data: Array<ListTravelRestrictionsResponse>): Array<ListTravelRestrictionsTableRow> => {
  return data.map(({
    id,
    passportCountry,
    originCountry,
    destination,
    status,
    inheritStatus,
    open,
    inheritOpen,
    openingDate,
    inheritOpeningDate,
    directAllowed,
    inheritDirectAllowed,
    directAllowedDate,
    inheritDirectAllowedDate,
    vaccinationRequired,
    inheritVaccinationRequired,
    vaccinationPolicyTranslations,
    inheritVaccinationPolicyTranslations,
    certificateAfterRequired,
    inheritCertificateAfterRequired,
    certificateAfterPolicyTranslations,
    inheritCertificateAfterPolicyTranslations,
    certificateBeforeRequired,
    inheritCertificateBeforeRequired,
    certificateBeforePolicyTranslations,
    inheritCertificateBeforePolicyTranslations,
    certificateRequired,
    inheritCertificateRequired,
    certificatePolicyTranslations,
    inheritCertificatePolicyTranslations,
    quarantineRequired,
    inheritQuarantineRequired,
    quarantinePolicyTranslations,
    inheritQuarantinePolicyTranslations,
    insuranceRequired,
    inheritInsuranceRequired,
    insurancePolicyTranslations,
    inheritInsurancePolicyTranslations,
    masksRequired,
    inheritMasksRequired,
    masksPolicyTranslations,
    inheritMasksPolicyTranslations,
    foodOpen,
    inheritFoodOpen,
    foodPolicyTranslations,
    inheritFoodPolicyTranslations,
    sources,
    internalRestrictionsTranslations,
    inheritInternalRestrictionsTranslations,
    entryRestrictionsTranslations,
    inheritEntryRestrictionsTranslations,
    forbiddenDirectCountries,
    inheritForbiddenDirectCountries,
    allowedLayoverAirports,
    inheritAllowedLayoverAirports,
    transitInterlineAvailable,
    inheritTransitInterlineAvailable,
    transitTranslations,
    inheritTransitTranslations,
    lastModified
  }) => ({
    id,
    passportCountry,
    originCountry,
    destination,
    status: inheritStatus ? inheritPlaceholder : status,
    open: inheritOpen ? inheritPlaceholder : open,
    openingDate: inheritOpeningDate ? inheritPlaceholder : openingDate,
    directAllowed: inheritDirectAllowed ? inheritPlaceholder : directAllowed,
    directAllowedDate: inheritDirectAllowedDate ? inheritPlaceholder : directAllowedDate,
    vaccinationRequired: inheritVaccinationRequired ? inheritPlaceholder : vaccinationRequired,
    vaccinationPolicyTranslations: inheritVaccinationPolicyTranslations ? inheritPlaceholder : vaccinationPolicyTranslations,
    certificateAfterRequired: inheritCertificateAfterRequired ? inheritPlaceholder : certificateAfterRequired,
    certificateAfterPolicyTranslations: inheritCertificateAfterPolicyTranslations ? inheritPlaceholder : certificateAfterPolicyTranslations,
    certificateBeforeRequired: inheritCertificateBeforeRequired ? inheritPlaceholder : certificateBeforeRequired,
    certificateBeforePolicyTranslations: inheritCertificateBeforePolicyTranslations ? inheritPlaceholder : certificateBeforePolicyTranslations,
    certificateRequired: inheritCertificateRequired ? inheritPlaceholder : certificateRequired,
    certificatePolicyTranslations: inheritCertificatePolicyTranslations ? inheritPlaceholder : certificatePolicyTranslations,
    quarantineRequired: inheritQuarantineRequired ? inheritPlaceholder : quarantineRequired,
    quarantinePolicyTranslations: inheritQuarantinePolicyTranslations ? inheritPlaceholder : quarantinePolicyTranslations,
    insuranceRequired: inheritInsuranceRequired ? inheritPlaceholder : insuranceRequired,
    insurancePolicyTranslations: inheritInsurancePolicyTranslations ? inheritPlaceholder : insurancePolicyTranslations,
    masksRequired: inheritMasksRequired ? inheritPlaceholder : masksRequired,
    masksPolicyTranslations: inheritMasksPolicyTranslations ? inheritPlaceholder : masksPolicyTranslations,
    foodOpen: inheritFoodOpen ? inheritPlaceholder : foodOpen,
    foodPolicyTranslations: inheritFoodPolicyTranslations ? inheritPlaceholder : foodPolicyTranslations,
    sources: sources,
    internalRestrictionsTranslations: inheritInternalRestrictionsTranslations ? inheritPlaceholder : internalRestrictionsTranslations,
    entryRestrictionsTranslations: inheritEntryRestrictionsTranslations ? inheritPlaceholder : entryRestrictionsTranslations,
    forbiddenDirectCountries: inheritForbiddenDirectCountries ? inheritPlaceholder : forbiddenDirectCountries,
    allowedLayoverAirports: inheritAllowedLayoverAirports ? inheritPlaceholder : allowedLayoverAirports,
    transitInterlineAvailable: inheritTransitInterlineAvailable ? inheritPlaceholder : transitInterlineAvailable,
    transitTranslations: inheritTransitTranslations ? inheritPlaceholder : transitTranslations,
    lastModified: moment.utc(lastModified).local()
  }))
}

export default (props: any) => {
  const accessRules = getRules()

  const createdTravelRestrictionId = props.location?.state?.createdId

  const columns: Array<object> = [
    {
      title: 'Passport Country',
      dataIndex: 'passportCountry',
      name: 'passportCountry',
      key: 'passportCountry',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'passportCountry'),
      render: ({ id, iata, translations }: Country) =>
        <Link to={`/countries/${id}`}>{translations ? translations.en.su : iata}</Link>
    },
    {
      title: 'Origin Country',
      dataIndex: 'originCountry',
      name: 'originCountry',
      key: 'originCountry',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'originCountry'),
      render: ({ id, iata, translations }: Country) =>
        <Link to={`/countries/${id}`}>{translations ? translations.en.su : iata}</Link>
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      name: 'destination',
      key: 'destination',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'destination'),
      render: ({ __typename, id, iata, translations }: Place) => {
        let link: string

        switch (__typename) {
          case "Country":
            link = `/countries/${id}`
            break
          case "City":
            link = `/cities/${id}`
            break
          case "Airport":
            link = `/airports/${id}`
            break
          default:
            return
        }

        return <span><Link to={link}>{translations ? translations.en.su : iata}</Link> ({__typename})</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status',
      key: 'status',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'status'),
      render: (status?: string | Inherit) =>
        status === inheritPlaceholder ?
          status :
          <span>{status == null ? 'No data' : status}</span>
    },
    {
      title: 'Open',
      dataIndex: 'open',
      name: 'open',
      key: 'open',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'open'),
      render: (open?: boolean | Inherit) =>
        open === inheritPlaceholder ?
          open :
          (open == null ? <span>No data</span> : <Switch disabled checked={open} />)
    },
    {
      title: 'Opening Date',
      dataIndex: 'openingDate',
      name: 'openingDate',
      key: 'openingDate',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'openingDate'),
      render: (openingDate?: Date | Inherit) =>
        openingDate === inheritPlaceholder ?
          openingDate :
          (openingDate == null ? <span>No data</span> : openingDate)
    },
    {
      title: 'Direct Allowed',
      dataIndex: 'directAllowed',
      name: 'directAllowed',
      key: 'directAllowed',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'directAllowed'),
      render: (directAllowed?: boolean | Inherit) =>
        directAllowed === inheritPlaceholder ?
          directAllowed :
          (directAllowed == null ? <span>No data</span> : <Switch disabled checked={directAllowed} />)
    },
    {
      title: 'Direct Allowed Date',
      dataIndex: 'directAllowedDate',
      name: 'directAllowedDate',
      key: 'directAllowedDate',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'directAllowedDate'),
      render: (directAllowedDate?: Date | Inherit) =>
        directAllowedDate === inheritPlaceholder ?
          directAllowedDate :
          (directAllowedDate == null ? <span>No data</span> : directAllowedDate)
    },
    {
      title: 'Vaccination Required',
      dataIndex: 'vaccinationRequired',
      name: 'vaccinationRequired',
      key: 'vaccinationRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'vaccinationRequired'),
      render: (vaccinationRequired?: boolean | Inherit) =>
        vaccinationRequired === inheritPlaceholder ?
          vaccinationRequired :
          (vaccinationRequired == null ? <span>No data</span> : <Switch disabled checked={vaccinationRequired} />)
    },
    {
      title: 'Vaccination Policy',
      dataIndex: 'vaccinationPolicyTranslations',
      name: 'vaccinationPolicyTranslations',
      key: 'vaccinationPolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'vaccinationPolicyTranslations'),
      render: (vaccinationPolicyTranslations?: Translations | Inherit) =>
        vaccinationPolicyTranslations === inheritPlaceholder ?
          vaccinationPolicyTranslations :
          (<Translation translations={vaccinationPolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Certificate After Required',
      dataIndex: 'certificateAfterRequired',
      name: 'certificateAfterRequired',
      key: 'certificateAfterRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificateAfterRequired'),
      render: (certificateAfterRequired?: boolean | Inherit) =>
        certificateAfterRequired === inheritPlaceholder ?
          certificateAfterRequired :
          (certificateAfterRequired == null ? <span>No data</span> : <Switch disabled checked={certificateAfterRequired} />)
    },
    {
      title: 'Certificate After Policy',
      dataIndex: 'certificateAfterPolicyTranslations',
      name: 'certificateAfterPolicyTranslations',
      key: 'certificateAfterPolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificateAfterPolicyTranslations'),
      render: (certificateAfterPolicyTranslations?: Translations | Inherit) =>
        certificateAfterPolicyTranslations === inheritPlaceholder ?
          certificateAfterPolicyTranslations :
          (<Translation translations={certificateAfterPolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Certificate Before Required',
      dataIndex: 'certificateBeforeRequired',
      name: 'certificateBeforeRequired',
      key: 'certificateBeforeRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificateBeforeRequired'),
      render: (certificateBeforeRequired?: boolean | Inherit) =>
        certificateBeforeRequired === inheritPlaceholder ?
          certificateBeforeRequired :
          (certificateBeforeRequired == null ? <span>No data</span> : <Switch disabled checked={certificateBeforeRequired} />)
    },
    {
      title: 'Certificate Before Policy',
      dataIndex: 'certificateBeforePolicyTranslations',
      name: 'certificateBeforePolicyTranslations',
      key: 'certificateBeforePolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificateBeforePolicyTranslations'),
      render: (certificateBeforePolicyTranslations?: Translations | Inherit) =>
        certificateBeforePolicyTranslations === inheritPlaceholder ?
          certificateBeforePolicyTranslations :
          (<Translation translations={certificateBeforePolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Certificate Required',
      dataIndex: 'certificateRequired',
      name: 'certificateRequired',
      key: 'certificateRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificateRequired'),
      render: (certificateRequired?: boolean | Inherit) =>
        certificateRequired === inheritPlaceholder ?
          certificateRequired :
          (certificateRequired == null ? <span>No data</span> : <Switch disabled checked={certificateRequired} />)
    },
    {
      title: 'Certificate Policy',
      dataIndex: 'certificatePolicyTranslations',
      name: 'certificatePolicyTranslations',
      key: 'certificatePolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'certificatePolicyTranslations'),
      render: (certificatePolicyTranslations?: Translations | Inherit) =>
        certificatePolicyTranslations === inheritPlaceholder ?
          certificatePolicyTranslations :
          (<Translation translations={certificatePolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Quarantine Required',
      dataIndex: 'quarantineRequired',
      name: 'quarantineRequired',
      key: 'quarantineRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'quarantineRequired'),
      render: (quarantineRequired?: boolean | Inherit) =>
        quarantineRequired === inheritPlaceholder ?
          quarantineRequired :
          (quarantineRequired == null ? <span>No data</span> : <Switch disabled checked={quarantineRequired} />)
    },
    {
      title: 'Quarantine Policy',
      dataIndex: 'quarantinePolicyTranslations',
      name: 'quarantinePolicyTranslations',
      key: 'quarantinePolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'quarantinePolicyTranslations'),
      render: (quarantinePolicyTranslations?: Translations | Inherit) =>
        quarantinePolicyTranslations === inheritPlaceholder ?
          quarantinePolicyTranslations :
          (<Translation translations={quarantinePolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Insurance Required',
      dataIndex: 'insuranceRequired',
      name: 'insuranceRequired',
      key: 'insuranceRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'insuranceRequired'),
      render: (insuranceRequired?: boolean | Inherit) =>
        insuranceRequired === inheritPlaceholder ?
          insuranceRequired :
          (insuranceRequired == null ? <span>No data</span> : <Switch disabled checked={insuranceRequired} />)
    },
    {
      title: 'Insurance Policy',
      dataIndex: 'insurancePolicyTranslations',
      name: 'insurancePolicyTranslations',
      key: 'insurancePolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'insurancePolicyTranslations'),
      render: (insurancePolicyTranslations?: Translations | Inherit) =>
        insurancePolicyTranslations === inheritPlaceholder ?
          insurancePolicyTranslations :
          (<Translation translations={insurancePolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Masks Required',
      dataIndex: 'masksRequired',
      name: 'masksRequired',
      key: 'masksRequired',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'masksRequired'),
      render: (masksRequired?: boolean | Inherit) =>
        masksRequired === inheritPlaceholder ?
          masksRequired :
          (masksRequired == null ? <span>No data</span> : <Switch disabled checked={masksRequired} />)
    },
    {
      title: 'Masks Policy',
      dataIndex: 'masksPolicyTranslations',
      name: 'masksPolicyTranslations',
      key: 'masksPolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'masksPolicyTranslations'),
      render: (masksPolicyTranslations?: Translations | Inherit) =>
        masksPolicyTranslations === inheritPlaceholder ?
          masksPolicyTranslations :
          (<Translation translations={masksPolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Food Open',
      dataIndex: 'foodOpen',
      name: 'foodOpen',
      key: 'foodOpen',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'foodOpen'),
      render: (foodOpen?: boolean | Inherit) =>
        foodOpen === inheritPlaceholder ?
          foodOpen :
          (foodOpen == null ? <span>No data</span> : <Switch disabled checked={foodOpen} />)
    },
    {
      title: 'Food Policy',
      dataIndex: 'foodPolicyTranslations',
      name: 'foodPolicyTranslations',
      key: 'foodPolicyTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'foodPolicyTranslations'),
      render: (foodPolicyTranslations?: Translations | Inherit) =>
        foodPolicyTranslations === inheritPlaceholder ?
          foodPolicyTranslations :
          (<Translation translations={foodPolicyTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Sources',
      dataIndex: 'sources',
      name: 'sources',
      key: 'sources',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'sources'),
      render: (sources: string[] | Inherit) =>
        <span>{sources.length} sources</span>
    },
    {
      title: 'Internal Restrictions',
      dataIndex: 'internalRestrictionsTranslations',
      name: 'internalRestrictionsTranslations',
      key: 'internalRestrictionsTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'internalRestrictionsTranslations'),
      render: (internalRestrictionsTranslations?: Translations | Inherit) =>
        internalRestrictionsTranslations === inheritPlaceholder ?
          internalRestrictionsTranslations :
          (<Translation translations={internalRestrictionsTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Entry Restrictions',
      dataIndex: 'entryRestrictionsTranslations',
      name: 'entryRestrictionsTranslations',
      key: 'entryRestrictionsTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'entryRestrictionsTranslations'),
      render: (entryRestrictionsTranslations?: Translations | Inherit) =>
        entryRestrictionsTranslations === inheritPlaceholder ?
          entryRestrictionsTranslations :
          (<Translation translations={entryRestrictionsTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Forbidden Direct Countries',
      dataIndex: 'forbiddenDirectCountries',
      name: 'forbiddenDirectCountries',
      key: 'forbiddenDirectCountries',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'forbiddenDirectCountries'),
      render: (forbiddenDirectCountries: Country[] | Inherit) =>
        forbiddenDirectCountries === inheritPlaceholder ?
          forbiddenDirectCountries :
          forbiddenDirectCountries.length > 0 ?
            forbiddenDirectCountries.map(({ id, iata, translations }, i) => [
              i > 0 && ", ",
              <Link to={`/countries/${id}`}>{translations ? translations.en.su : iata}</Link>
            ]) : 'No forbidden'
    },
    {
      title: 'Allowed Layover Airports',
      dataIndex: 'allowedLayoverAirports',
      name: 'allowedLayoverAirports',
      key: 'allowedLayoverAirports',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'allowedLayoverAirports'),
      render: (allowedLayoverAirports: Airport[] | Inherit) =>
        allowedLayoverAirports === inheritPlaceholder ?
          allowedLayoverAirports :
          allowedLayoverAirports.length > 0 ?
            allowedLayoverAirports.map(({ id, iata, translations }, i) => [
              i > 0 && ", ",
              <Link to={`/airports/${id}`}>{translations ? translations.en.su : iata}</Link>
            ]) : 'All allowed'
    },
    {
      title: 'Transit Interline Available',
      dataIndex: 'transitInterlineAvailable',
      name: 'transitInterlineAvailable',
      key: 'transitInterlineAvailable',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'transitInterlineAvailable'),
      render: (transitInterlineAvailable?: boolean | Inherit) =>
        transitInterlineAvailable === inheritPlaceholder ?
          transitInterlineAvailable :
          (transitInterlineAvailable == null ? <span>No data</span> : <Switch disabled checked={transitInterlineAvailable} />)
    },
    {
      title: 'Transit',
      dataIndex: 'transitTranslations',
      name: 'transitTranslations',
      key: 'transitTranslations',
      width: '10vw',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'transitTranslations'),
      render: (transitTranslations?: Translations | Inherit) =>
        transitTranslations === inheritPlaceholder ?
          transitTranslations :
          (<Translation translations={transitTranslations} locales={["ru", "en"]} />)
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      name: 'lastModified',
      key: 'lastModified',
      hidden: notAllowedToShow(accessRules, 'travelRestriction', 'lastModified'),
      render: (date: moment.Moment) =>
        <span>{date.format('YYYY-MM-DD')}</span>
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 100,
      fixed: 'right',
      hidden: notAllowedToEdit(accessRules, 'travelRestriction'),
      render: (id: number) =>
        <span><Link to={`/explore/travel_restrictions/${id}`}>Edit</Link></span>,
    }
  ]

  return (
    <>
      {createdTravelRestrictionId && <Alert closable type='success' message={`Travel restriction with id ${createdTravelRestrictionId} created successfully`}></Alert>}
      {
        prepareComponents('travel-restrictions-list', [{
          component: <IndexList
            request={{ query: ListTravelRestrictionsQ, datakey: 'travelRestrictions', transform: responseToTable }}
            columns={columns}
            title='Travel Restrictions'
            rowKey='id'
            newEntityPath='/explore/travel_restrictions/new'
            showCreateButton={true}
            scroll={{ x: '200vw' }}
            filterFields={[
              { key: 'passportCountry', path: 'passportCountry.translations' },
              { key: 'originCountry', path: 'originCountry.translations' },
              { key: 'destination', path: 'destination.translations' }
            ]}
            sortFields={[
              { key: 'status' },
              { key: 'open' },
              { key: 'openingDate' },
              { key: 'directAllowed' },
              { key: 'vaccinationRequired' },
              { key: 'vaccinationPolicyTranslations' },
              { key: 'certificateAfterRequired' },
              { key: 'certificateAfterPolicyTranslations' },
              { key: 'certificateBeforeRequired' },
              { key: 'certificateBeforePolicyTranslations' },
              { key: 'certificateRequired' },
              { key: 'certificatePolicyTranslations' },
              { key: 'quarantineRequired' },
              { key: 'quarantinePolicyTranslations' },
              { key: 'insuranceRequired' },
              { key: 'insurancePolicyTranslations' },
              { key: 'masksRequired' },
              { key: 'masksPolicyTranslations' },
              { key: 'foodOpen' },
              { key: 'foodPolicyTranslations' },
              { key: 'sources' },
              { key: 'internalRestrictionsTranslations' },
              { key: 'entryRestrictionsTranslations' },
              { key: 'transitInterlineAvailable' },
              { key: 'transitTranslations' },
              { key: 'lastModified', default: 'descend' }
            ]}
          />,
          rule: getRule(accessRules, 'query', 'travelRestrictions')
        }])
      }
    </>
  )
}
