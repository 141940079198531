import React, {useMemo} from 'react'
import {Select, Form} from 'antd'
import {Translations} from '../../../types'
import {translationsContainString} from '../../../utils'
import LatestChange, {Data as LatestChangeData} from "../latest_change";

interface AirportInputProps {
  onChange: (e: any) => void,
  value?: number,
  disabled: boolean,
  label?: string,
  formItemDisabled?: boolean,
  airports: Array<{ id: number, iata: string, translations: Translations }>,
  multi?: boolean,
  latestChange?: LatestChangeData
}

interface SelectOption {
  label: string;
  value: number;
  iata?: string;
  translations?: Translations;
}

const filterOption = (searchStr: string, option: SelectOption | undefined) => {
  if (!option) return false;
  const { translations, iata} = option
  const lowercaseSearch = searchStr.toLowerCase()

  const isTranslationsMatch = translations ? translationsContainString(translations, lowercaseSearch) : false
  const isIataMatch = iata?.toLowerCase()?.includes(lowercaseSearch)
  return isTranslationsMatch || isIataMatch
}

export default ({onChange, airports, label, formItemDisabled, disabled, value, multi, latestChange}: AirportInputProps) => {

  const options = useMemo(
    () => airports.map(({ id, translations, iata}) =>
      ({ label: translations?.en?.su, value: id, translations, iata })),
      [airports]
      );


  const select = (
    <Select
      mode={multi ? "multiple" : undefined}
      disabled={disabled}
      value={value}
      style={{width: '80%', alignSelf: 'center'}}
      onChange={onChange}
      filterOption={filterOption as any}
      options={options}
      showSearch={true}
    >
      {!multi &&
      <Select.Option value={-1} key='null'>
        Not selected
      </Select.Option>
      }
    </Select>
  )

  if (formItemDisabled)
    return select
  else
    return <Form.Item label={label ? label : 'Airport'}>
      {select}
      <LatestChange data={latestChange}/>
    </Form.Item>
}
