import React, { useState } from 'react'
import { useMutation } from '../../../../mutation'
import { useQuery } from '../../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import * as yup from 'yup'
import equal from 'fast-deep-equal'
import { UpdateTravelRestrictionSummaryM } from './mutations'
import { Translations } from '../../../../types'
import { TravelRestrictionSummaryQ, InputsDataQ } from './queries'
import TranslationsInput from '../../../shared/translations'
import CountryInput from '../../../shared/inputs/country'
import NullableBoolInput from '../../../shared/inputs/nullable_bool'
import PlaceType from '../../../shared/types/place';

import '../../../shared/form.css'

import { processErrors, preprocessJSONInputsGently, prepareMutationInput, prepareComponents } from '../../../../utils'
import { getRules, getRule } from '../../../../access_rules'
import PlaceInputNullable from "../../../shared/inputs/places_nullable";

interface UpdateTravelRestrictionSummaryInput {
  passportCountryId?: number
  destinationType?: PlaceType | null
  destinationId?: number | null
  open?: boolean | null
  hasOpeningDate?: boolean | null
  directAllowed?: boolean | null
  translations?: Translations | null
}

export const schema = yup.object().shape({
  passportCountryId: yup.number().min(1).required(),
  destinationType: yup.string().nullable(),
  destinationId: yup.number().min(1).nullable(),
  open: yup.boolean().nullable(),
  hasOpeningDate: yup.boolean().nullable(),
  directAllowed: yup.boolean().nullable(),
  translations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).nullable()
})

export default (props) => {
  const id = parseInt(props.match.params.id)

  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<UpdateTravelRestrictionSummaryInput>({})

  const {
    data: travelRestrictionSummaryData,
    error: travelRestrictionSummaryLoadingError,
    loading: travelRestrictionSummaryLoading
  } = useQuery(TravelRestrictionSummaryQ, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  const [updateTravelRestrictionSummary, { data: mutationData }] = useMutation(UpdateTravelRestrictionSummaryM, setErrors)

  if (travelRestrictionSummaryLoadingError) return (<Alert type='error' message='Travel Restriction Summary fetching error' />)
  if (travelRestrictionSummaryLoading) return (<Spin size='large' />)
  if (travelRestrictionSummaryData.travelRestrictionSummary === undefined) return (<></>)

  const { travelRestrictionSummary } = travelRestrictionSummaryData

  const destinationType: PlaceType = travelRestrictionSummary.destination ?
    travelRestrictionSummary.destination.__typename.toUpperCase() : null

  const defaultInput = {
    passportCountryId: travelRestrictionSummary.passportCountry.id,
    destinationType: destinationType,
    destinationId: travelRestrictionSummary.destination ? travelRestrictionSummary.destination.id : null,
    open: travelRestrictionSummary.open,
    hasOpeningDate: travelRestrictionSummary.hasOpeningDate,
    directAllowed: travelRestrictionSummary.directAllowed,
    translations: travelRestrictionSummary.translations
  }

  if (Object.keys(input).length === 0) setInput(defaultInput)

  if (mutationData) history.push(`/explore/travel_restrictions/summaries`)

  const performMutation = () => schema.validate(input)
    .then(_ => {
      const mutationInput = Object.entries(prepareMutationInput(defaultInput, input)).reduce((newObj, [key, value]) => {
        switch (key) {
          case 'destinationId':
            newObj[key] = value
            newObj['destinationType'] = input.destinationType
            break

          default:
            newObj[key] = value
        }
        return newObj;
      }, {});

      return updateTravelRestrictionSummary({ variables: { input: preprocessJSONInputsGently(mutationInput), id } })
    }, e => processErrors(e, setErrors))

  const accessRules = getRules();

  const inputComponents = {
    translations: [
      {
        component: <TranslationsInput
          multiline
          setTranslations={translations => setInput({ ...input, translations })}
          translations={input.translations}
          localesOrder={["ru", "en"]}
        />,
        rule: getRule(accessRules, 'travelRestrictionSummary', 'translations')
      }
    ]
  }

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
            accessRules={accessRules}
          />,
          translations: prepareComponents('translations', inputComponents.translations)
        }[activeTab]
      }

      {
        (activeTab !== 'changelog') &&
        <>
          <Button
            style={{ float: 'left' }}
            disabled={equal(input, defaultInput)}
            onClick={() => setInput(defaultInput)}
            children='Reset Changes'
          />
          <Button
            onClick={performMutation}
            style={{ float: 'right' }}
            type='primary'
            children='Update Travel Restriction Summary'
          />
        </>
      }
    </Card>
  )
}

const MainForm = (props: any) => {
  const { input, setInput, accessRules } = props;

  const { loading, error: qError, data: inputsData } = useQuery(InputsDataQ)

  if (loading) return (<Spin />)
  if (qError) return (<Alert message='Countries fetching error' />)

  const inputComponents = [
    {
      component: <CountryInput
        label='Passport Country'
        countries={inputsData.countries}
        value={input.passportCountryId}
        disabled={loading}
        onChange={passportCountryId => setInput({ ...input, passportCountryId })}
      />,
      rule: getRule(accessRules, 'travelRestrictionSummary', 'passportCountry')
    },
    {
      component: <PlaceInputNullable
        label='Destination'
        onTypeChange={destinationType => setInput({ ...input, destinationType, destinationId: null })}
        onIdChange={destinationId => setInput({ ...input, destinationId })}
        typeValue={input.destinationType}
        idValue={input.destinationId}
        disabled={loading}
        countries={inputsData.countries}
        cities={inputsData.cities}
        airports={inputsData.airports}
      />,
      rule: getRule(accessRules, 'travelRestrictionSummary', 'destination')
    },
    {
      component: <NullableBoolInput
        label='Open'
        value={input.open}
        disabled={loading}
        onChange={open => setInput({ ...input, open })}
      />,
      rule: getRule(accessRules, 'travelRestrictionSummary', 'open')
    },
    {
      component: <NullableBoolInput
        label='Has Opening Date'
        value={input.hasOpeningDate}
        disabled={loading}
        onChange={hasOpeningDate => setInput({ ...input, hasOpeningDate })}
      />,
      rule: getRule(accessRules, 'travelRestrictionSummary', 'hasOpeningDate')
    },
    {
      component: <NullableBoolInput
        label='Direct Allowed'
        value={input.directAllowed}
        disabled={loading}
        onChange={directAllowed => setInput({ ...input, directAllowed })}
      />,
      rule: getRule(accessRules, 'travelRestrictionSummary', 'directAllowed'),
    }
  ]

  return (
    <>
      {
        prepareComponents('main-form', inputComponents)
      }
    </>
  )
}
