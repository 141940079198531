import React, { useState } from 'react'
import { useMutation } from '../../../../mutation'
import { useQuery } from '../../../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import * as yup from 'yup'

import { CreateTravelRestrictionSummaryM } from './mutations'
import { Translations } from '../../../../types'
import { InputsDataQ } from './queries'

import TranslationsInput from '../../../shared/translations'
import PlaceInputNullable from '../../../shared/inputs/places_nullable'
import PlaceType from '../../../shared/types/place'

import '../../../shared/form.css'

import { processErrors } from '../../../../utils'
import CountryInput from "../../../shared/inputs/country";
import NullableBoolInput from "../../../shared/inputs/nullable_bool";
import { preprocessInputTranslations } from "../../../../utils";

interface CreateTravelRestrictionSummaryInput {
  passportCountryId?: number
  destinationType?: PlaceType | null
  destinationId?: number | null
  open?: boolean | null
  hasOpeningDate?: boolean | null
  directAllowed?: boolean | null
  translations?: Translations
}

export const schema = yup.object().shape({
  passportCountryId: yup.number().min(1).required(),
  destinationType: yup.string().nullable(),
  destinationId: yup.number().min(1).nullable(),
  open: yup.boolean().nullable(),
  hasOpeningDate: yup.boolean().nullable(),
  directAllowed: yup.boolean().nullable(),
  translations: yup.object().shape({
    en: yup.object().shape({
      su: yup.string()
    })
  }).required()
})

const defaultInput = {
  passportCountryId: -1,
  destinationType: null,
  destinationId: null,
  open: null,
  hasOpeningDate: null,
  directAllowed: null,
  translations: {}
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateTravelRestrictionSummaryInput>(defaultInput)
  const [createTravelRestrictionSummary, { data: mutationData }] = useMutation(CreateTravelRestrictionSummaryM, setErrors)

  const performMutation = () =>
    schema.validate(input)
      .then(_ => createTravelRestrictionSummary({ variables: { input: preprocessInputTranslations(input) } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({
    pathname: `/explore/travel_restrictions/summaries`,
    state: { createdId: mutationData.createTravelRestrictionSummary.id }
  })

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            setTranslations={translations => setInput({ ...input, translations })}
            translations={input.translations}
          />
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        disabled={false}
        type='primary'
        htmlType='submit'
        style={{ float: 'right' }}
        children='Save'
        onClick={performMutation}
      />

    </Card>
  )
}
const MainForm = ({ input, setInput }) => {
  const { loading, error: qError, data: inputsData } = useQuery(InputsDataQ)

  if (loading) return (<Spin />)
  if (qError) return (<Alert message='Countries fetching error' />)

  return (
    <>
      <CountryInput
        label='Passport Country'
        countries={inputsData.countries}
        value={input.passportCountryId}
        disabled={loading}
        onChange={passportCountryId => setInput({ ...input, passportCountryId })}
      />

      <PlaceInputNullable
        label='Destination'
        onTypeChange={destinationType => setInput({ ...input, destinationType, destinationId: null })}
        onIdChange={destinationId => setInput({ ...input, destinationId })}
        typeValue={input.destinationType}
        idValue={input.destinationId}
        disabled={loading}
        countries={inputsData.countries}
        cities={inputsData.cities}
        airports={inputsData.airports}
      />

      <NullableBoolInput
        label='Open'
        value={input.open}
        disabled={loading}
        onChange={open => setInput({ ...input, open })}
      />

      <NullableBoolInput
        label='Has Opening Date'
        value={input.hasOpeningDate}
        disabled={loading}
        onChange={hasOpeningDate => setInput({ ...input, hasOpeningDate })}
      />

      <NullableBoolInput
        label='Direct Allowed'
        value={input.directAllowed}
        disabled={loading}
        onChange={directAllowed => setInput({ ...input, directAllowed })}
      />
    </>
  )
}
