import React from 'react'
import { Switch, Route } from 'react-router-dom'

import List from './list'
import New from './new'
import Edit from './edit'

export default () =>
  <Switch>
    <Route path='/explore/travel_restrictions' exact component={List} />
    <Route path='/explore/travel_restrictions/new' component={New} />
    <Route path='/explore/travel_restrictions/:id' component={Edit} />
  </Switch>
