import React from 'react'
import {Form, DatePicker} from 'antd'
import moment from 'moment'
import LatestChange, {Data as LatestChangeData} from '../latest_change'

interface DateInputProps {
  onChange: (_) => void,
  disabled?: boolean,
  value: Date,
  label: string
  latestChange?: LatestChangeData
}

export default ({ onChange, disabled, value, label, latestChange }: DateInputProps) =>
  <Form.Item label={label}>
    <DatePicker
      disabled={disabled}
      onChange={onChange}
      defaultValue={value === null ? undefined : moment(value, 'YYYY/MM/DD')}
    />
    <LatestChange data={latestChange} />
  </Form.Item>
